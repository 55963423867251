import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { Box } from '@mui/material'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import {
    ColDef,
    SideBarDef,
    GridReadyEvent,
    GetMainMenuItemsParams,
    MenuItemDef,
    CellDoubleClickedEvent,
    CellClickedEvent,
    SelectionChangedEvent,
    RowNode,
    ProcessCellForExportParams,
    NewValueParams,
    ShouldRowBeSkippedParams,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { clientTheme } from 'theme-exports'
import {
    MerchantMidsGridDetails,
    ReconciliationReportGridDetails,
    AGDataGridLoadingIndicator,
    AGDataGridSelectAllCheckbox,
} from './index'
import { PaginationAGGrid } from 'components'
import { IPaginationAlertContent } from 'components/PaginationAGGrid/PaginationAGGrid'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import idDirectory from './idAttributes'

type MasterDetailTypes = 'midDetails' | 'reconciliationReportDetails' | ''

interface AGDataGridProps {
    gridRef: any
    gridName: string
    columns: any[]
    dataSource: any
    className?: string
    defaultColDef?: {
        [key: string]: any
    }
    testId: string
    loading?: boolean
    pageSize?: number
    paginate?: boolean
    totalEntries: number
    rowsPerPageOptions?: number[]
    onPageLimitChange?: (size: number) => void
    onPageChange?: (page: number) => void
    page?: number
    masterDetailType?: MasterDetailTypes
    masterDetailRowHeight?: number
    enableCustomContextMenuItems?: boolean
    getCustomContextMenuItems?: any
    rowHeight?: number
    headerHeight?: number
    rowSelectionType?: 'single' | 'multiple'
    handleCellValueChanged?: (params: NewValueParams) => void
    handleDoubleClickedCell?: (e: CellDoubleClickedEvent) => void
    handleRowClicked?: (e: CellClickedEvent) => void
    handleIsRowSelectable?: (params: RowNode<any>) => boolean
    handleSelectionChanged?: () => void
    enableSelectAllCheckbox?: boolean
    handleSelectAllCheckbox?: (value: any) => void
    selectCheckboxColumnName?: string
    hideSelectCheckboxByDefault?: boolean
    hideSelectCheckboxInColumnsToolPanel?: boolean
    enableSelectOneCheckbox?: boolean
    autoSizeColumnList?: string[]
    enableSaveGridColumnFilterState?: boolean
    disableRowClickSelection?: boolean
    disableCellFocus?: boolean
    fullHeightContainerRef?: any
    enableSideBarPanel?: boolean
    enablePageLimitOptions?: boolean
    enablePaginationPageChange?: boolean
    enablePaginationRefreshBtn?: boolean
    enablePaginationDisplayingText?: boolean
    selectedRowIndex?: number | null
    refreshDataGrid?: () => void
    refreshDataGridColumnsToggle?: boolean // Use to trigger the useMemo on 'columnDefs' otherwise grid will not update (for example, when truncating a cell (ERT data grid)).
    sidebarDetails?: { [key: string]: any } // Object to hold any info need for sidebar toolpanel.
    enableColumnFilterSidebar?: boolean
    fileName?: string
    excludedExportColumns?: string[]
    handleFormatExportCells?: (params: ProcessCellForExportParams) => void
    handleSkipExportRow?: (params: ShouldRowBeSkippedParams) => void
    enableLoadingOverlay?: boolean
    enableEmptyDataGrid?: boolean
    emptyDataGridOverlay?: string
    enableFirstRowSelection?: boolean
    gridThemeType?: 'alpine' | 'material' | 'materialSlim'
    enableDataGridTopBorder?: boolean
    enableAutoVerticalScroll?: boolean
    alwaysShowHorizontalScroll?: boolean
    paginationAlertContent?: IPaginationAlertContent
    dataGridBottomBar?: React.ReactNode
    offsetDataGridHeightElementId?: string // Use to get an element by ID for calculating AG grid height (for example, when adding an element BELOW AG data grid).
}

const stylesObj = {
    alpineRoot: {
        '& .ag-theme-alpine': {
            '--ag-font-size': `${clientTheme.typography.body1.fontSize} !important`,
            '--ag-alpine-active-color': `${clientTheme.secondary} !important`,
            '--ag-font-family': `${clientTheme.typography.fontFamily.join(
                ','
            )} !important`,
        },
        '--ag-font-family': `${clientTheme.typography.fontFamily.join(
            ','
        )} !important`,
        '& .ag-side-button-button': {
            fontSize: clientTheme.typography.body1.fontSize,
        },
        '--ag-alpine-active-color': `${clientTheme.secondary} !important`,
        '--ag-header-background-color': '#fff !important',
        '& .ag-header-cell': {
            fontFamily: clientTheme.typography.fontFamily.join(','),
            backgroundColor: '#fff',
            color: clientTheme.typography.fontColor.primaryText
                ? clientTheme.typography.fontColor.primaryText
                : '#545454',
            fontSize: clientTheme.typography.body1.fontSize,
        },
        '& .ag-body-viewport': {
            fontFamily: clientTheme.typography.fontFamily.join(','),
        },
        '& .ag-cell': {
            color: clientTheme.typography.fontColor.primaryText
                ? clientTheme.typography.fontColor.primaryText
                : '#464646',
        },
        '& .ag-menu-option-icon': {
            color: clientTheme.secondary,
        },
        '& .ag-checkbox-input-wrapper': {
            marginBottom: '4px',
        },
        '& .ag-cell-wrap-text': {
            wordBreak: 'break-word',
        },
    },
    materialRoot: {
        '& .ag-theme-alpine': {
            '--ag-font-size': `${clientTheme.typography.body1.fontSize} !important`,
            '--ag-alpine-active-color': `${clientTheme.secondary} !important`,
            '--ag-font-family': `${clientTheme.typography.fontFamily.join(
                ','
            )} !important`,
            '--ag-checkbox-checked-color': `${clientTheme.secondary} !important`,
        },
        '--ag-font-family': `${clientTheme.typography.fontFamily.join(
            ','
        )} !important`,
        '--ag-alpine-active-color': `${clientTheme.secondary} !important`,
        '--ag-header-background-color': '#fff !important',
        '& .ag-root-wrapper': {
            borderLeft: 'none',
            borderRight: 'none',
            '--ag-border-color': '#e9e9e9',
        },
        '& .ag-header-cell': {
            fontFamily: clientTheme.typography.fontFamily.join(','),
            fontSize: clientTheme.typography.body1.fontSize,
            backgroundColor: '#fff',
            color: clientTheme.typography.fontColor.primaryText
                ? clientTheme.typography.fontColor.primaryText
                : '#545454',
        },
        '& .ag-header-cell-resize': {
            opacity: 0,
        },
        '& .ag-cell': {
            fontFamily: clientTheme.typography.fontFamily.join(','),
            fontSize: clientTheme.typography.body1.fontSize,
            color: clientTheme.typography.fontColor.primaryText
                ? clientTheme.typography.fontColor.primaryText
                : '#464646',
        },
        '& .ag-row': {
            borderBottom: '1px solid #eeeeee',
        },
        '.ag-row-odd': {
            backgroundColor: 'hsla(0, 0%, 80%, 0.1)',
        },
        '& .ag-tool-panel-wrapper': {
            backgroundColor: '#fff',
        },
        '& .ag-side-button-button': {
            fontSize: clientTheme.typography.body1.fontSize,
        },
        '& .ag-menu-option-icon': {
            color: clientTheme.secondary,
        },
        '& .ag-column-panel-column-select': {
            borderTop: 'none',
            borderBottom: 'none',
        },
        '& .ag-column-select-header': {
            height: '46px',
        },
        '& .ag-cell-wrap-text': {
            wordBreak: 'break-word',
        },
    },
    materialSlim: {
        '& .ag-header': {
            backgroundColor: '#f8f8f8',
        },
        '& .ag-header-cell': {
            backgroundColor: '#f8f8f8',
            borderRight: '1px solid #e9e9e9',
        },
        '& .ag-ltr .ag-cell': {
            borderRight: '1px solid #e9e9e9',
        },
        '& .ag-checkbox-input-wrapper': {
            marginBottom: '4px',
        },
        '& .ag-side-bar': {
            backgroundColor: '#f8f8f8',
        },
    },
}

const AGDataGrid = ({
    gridRef,
    gridName,
    className,
    columns,
    dataSource,
    defaultColDef = { flex: 1, minWidth: 100, resizable: true },
    testId,
    loading = false,
    pageSize = 5,
    paginate = true,
    totalEntries,
    rowsPerPageOptions = [10, 25, 50, 100],
    onPageLimitChange = () => {},
    onPageChange = () => {},
    page = 0,
    masterDetailType = '',
    masterDetailRowHeight = 250,
    enableCustomContextMenuItems = false,
    getCustomContextMenuItems,
    rowHeight = 0,
    headerHeight = 0,
    rowSelectionType = 'single',
    handleCellValueChanged = () => {},
    handleDoubleClickedCell = () => {},
    handleRowClicked = () => {},
    handleIsRowSelectable = () => true,
    handleSelectionChanged = () => {},
    enableSelectAllCheckbox = false,
    handleSelectAllCheckbox = () => {},
    selectCheckboxColumnName = '',
    hideSelectCheckboxByDefault = false,
    hideSelectCheckboxInColumnsToolPanel = true,
    enableSelectOneCheckbox = false,
    autoSizeColumnList = [],
    enableSaveGridColumnFilterState = true,
    disableRowClickSelection = false,
    disableCellFocus = true,
    fullHeightContainerRef,
    enableSideBarPanel = true,
    enablePageLimitOptions = true,
    enablePaginationPageChange = true,
    enablePaginationRefreshBtn = true,
    enablePaginationDisplayingText = true,
    selectedRowIndex = null,
    refreshDataGrid,
    refreshDataGridColumnsToggle = false,
    sidebarDetails = {},
    enableColumnFilterSidebar = true,
    fileName = 'export',
    excludedExportColumns = ['select', 'actions'],
    handleFormatExportCells = undefined,
    handleSkipExportRow = undefined,
    enableLoadingOverlay = false,
    enableEmptyDataGrid = false,
    emptyDataGridOverlay = '',
    enableFirstRowSelection = false,
    gridThemeType = 'alpine',
    enableDataGridTopBorder = true,
    enableAutoVerticalScroll = false,
    alwaysShowHorizontalScroll = true,
    paginationAlertContent = {
        enable: false,
        message: '',
        info: '',
        intent: 'info',
    },
    dataGridBottomBar = null,
    offsetDataGridHeightElementId = '',
}: AGDataGridProps): React.ReactElement => {
    const { ZEBRA_STRIPED_ROWS } = useFeatureToggle('DATAGRID')

    const gridThemeTypes = {
        alpine: {
            style: stylesObj.alpineRoot,
            headerHeight: 30,
            rowHeight: 30,
            paginationBorder: true,
        },
        material: {
            style: stylesObj.materialRoot,
            headerHeight: 45,
            rowHeight: 45,
            paginationBorder: false,
        },
        materialSlim: {
            style: { ...stylesObj.materialRoot, ...stylesObj.materialSlim },
            headerHeight: 30,
            rowHeight: 30,
            paginationBorder: false,
        },
    }

    const gridTheme = gridThemeTypes[gridThemeType]

    const resizeGridHeight = () => {
        if (!fullHeightContainerRef) return
        const current = fullHeightContainerRef.current
        if (!current) return
        const gridContainer = current.getElementsByClassName('ag-layout-auto-height')[0] as HTMLElement
        const gridViewportContainer = current.getElementsByClassName('ag-root-wrapper-body')[0] as HTMLElement
        const paginationContainer = current.getElementsByClassName('ag-pagination-container')[0] as HTMLElement
        const bottomBarContainer = current.getElementsByClassName("ag-bottomBar-container")[0] as HTMLElement
        const gridContainerOffsetTop = gridContainer?.offsetTop ?? 0
        const paginationContainerHeight = paginationContainer?.offsetHeight ?? 0
        const bottomBarContainerHeight = bottomBarContainer?.offsetHeight ?? 0
        const appFooterContainer = document.getElementById('standardFooter-footer-footer')
        const appExtraContainer = document.getElementById(offsetDataGridHeightElementId)
        const footerHeight = appFooterContainer ? appFooterContainer.offsetHeight : 0
        const extraHeight = appExtraContainer ? appExtraContainer.offsetHeight : 0
        // The 24px "magic number" is to take the 24px of padding on the parent card components into consideration.
        const gridContainerHeightToSet =
            gridContainerOffsetTop +
            paginationContainerHeight +
            bottomBarContainerHeight +
            footerHeight +
            extraHeight +
            24
        try {
            gridContainer.style.height = `calc(100vh - ${gridContainerHeightToSet}px)`
            gridContainer.style.minHeight = `135px`
            gridViewportContainer.style.height = `${gridContainer.offsetHeight}px`
        } catch(e) {}
    }

    const resizeObserver = new ResizeObserver(() => {
        resizeGridHeight()
    })

    React.useEffect(() => {
        resizeObserver.observe(document.body)
        return () => {
            resizeObserver.unobserve(document.body)
        }
    }, [resizeObserver])

    const [isAllChecked, setIsAllChecked] = useState<boolean>(false)

    const transformedColumns: ColDef[] = columns.map((col) => {
        return {
            headerName: col.Header,
            field: col.accessor,
            colId: col.colId ?? undefined,
            cellRenderer: col.Cell ?? undefined,
            cellRendererParams: col.cellParams ?? undefined,
            sortable: col.sortable ?? undefined,
            width: col.width ?? undefined,
            minWidth: col.minWidth ?? undefined,
            maxWidth: col.maxWidth ?? undefined,
            valueFormatter: col.format ?? undefined,
            pinned: col.pinned ?? undefined,
            lockPosition: col.lockPosition ?? undefined,
            lockPinned: col.lockPinned ?? undefined,
            suppressColumnsToolPanel: col.disableColumnFilter ?? undefined,
            filter: col.filter ?? undefined,
            menuTabs: col.menuTabs ?? undefined,
            resizable: col.resizable ?? undefined,
            headerTooltip: col.Header ?? undefined,
            wrapText: col.wrapText ?? undefined,
            autoHeight: col.autoHeight ?? undefined,
            // If adding 'cellStyle' in column definitions, then need to also include fontSize.
            cellStyle: col.cellStyle ?? {
                fontSize: clientTheme.typography.body1.fontSize,
            },
            tooltipField: col.tooltipField ?? undefined,
            hide: col.hide ?? undefined,
            tooltipValueGetter: col.tooltipValueGetter ?? undefined,
            editable: col.editable ?? undefined,
            cellEditor: col.cellEditor ?? undefined,
            cellEditorParams: col.cellEditorParams ?? undefined,
            cellEditorPopup: col.cellEditorPopup ?? undefined,
            cellEditorPopupPosition: col.cellEditorPopupPosition ?? undefined,
            wrapHeaderText: col.wrapHeaderText ?? undefined,
            sortIndex: col.sortIndex ?? undefined,
        }
    })
        
    

    const gridColumns: ColDef[] = enableSelectOneCheckbox
        ? [
              {
                  headerName: selectCheckboxColumnName,
                  field: 'select',
                  minWidth: 55,
                  maxWidth: 55,
                  lockPosition: 'left',
                  suppressColumnsToolPanel: hideSelectCheckboxInColumnsToolPanel,
                  lockPinned: true,
                  menuTabs: [],
                  cellStyle: { textAlign: 'center' },
                  checkboxSelection: true,
                  showDisabledCheckboxes: true,
                  ...(enableSelectAllCheckbox && {
                      headerComponent: AGDataGridSelectAllCheckbox,
                      headerComponentParams: {
                          isAllChecked,
                          setIsAllChecked,
                          handleSelectAllCheckbox,
                      },
                  }),
                  hide: hideSelectCheckboxByDefault,
              },
              ...transformedColumns,
          ]
        : transformedColumns

    const columnDefs = useMemo<ColDef[]>(() => {
        return gridColumns
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAllChecked, columns.length, refreshDataGridColumnsToggle])

    // Needed to prevent column state from resetting. When user clicks on 'All checkbox' or when using ‘refreshDataGridColumnsToggle’. 
    useEffect(() => {
        const colState = localStorage.getItem(`col-${gridName}`)
        if (
            colState &&
            colState !== 'undefined' &&
            gridRef?.current!.columnApi
        ) {
            gridRef?.current!.columnApi.applyColumnState({
                state: JSON.parse(colState),
                applyOrder: true,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAllChecked, refreshDataGridColumnsToggle])

    const defaultColumnDef = useMemo<ColDef>(() => {
        return defaultColDef
    }, [defaultColDef])

    const loadingOverlayComponent = useMemo<any>(() => {
        return AGDataGridLoadingIndicator
    }, [])

    const getRowId = useMemo(() => {
        return (params: any) =>
            ['number', 'string'].includes(typeof params.data.id)
                ? params.data?.id
                : params.data?.id?.value
                ? params.data?.id?.value
                : params.data?.rowIndex
    }, [])

    const overlayNoRowsTemplate = `<span class="ag-overlay-loading-center">No Records Found!</span>`

    const masterDetailCellRenderer = useMemo(() => {
        switch (masterDetailType) {
            case 'reconciliationReportDetails':
                return ReconciliationReportGridDetails
            default:
                break
        }
    }, [masterDetailType])

    const resetColumnFilter = () => {
        gridRef.current!.columnApi.resetColumnState()
        gridRef.current!.api.setFilterModel(null)
        gridRef.current!.api.deselectAll(null)
        localStorage.removeItem(`col-${gridName}`)
        localStorage.removeItem(`filter-${gridName}`)
    }

    const getColumnMenuItems = useCallback(
        (params: GetMainMenuItemsParams): (string | MenuItemDef)[] => {
            return [
                'pinSubMenu',
                'separator',
                'autoSizeThis',
                'autoSizeAll',
                'separator',
                {
                    name: 'Reset Columns',
                    action: () => {
                        resetColumnFilter()
                    },
                },
            ]
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [gridName, gridRef]
    )

    const getDefaultContextMenuItems = useCallback(() => {
        const resetMenuItem = {
            name: 'Reset Columns',
            action: () => {
                resetColumnFilter()
            },
        }

        const collapseAllMenuItem = {
            name: 'Collapse All',
            action: () => {
                gridRef.current!.api.forEachNode((node: any) => {
                    node.setExpanded(false)
                })
            },
        }

        const exportColumnKeys = gridRef
            .current!.api?.getColumnDefs()
            .filter(
                (col: any) =>
                    !excludedExportColumns.includes(col.colId) && !col.hide
            )
            .map((col: any) => col.colId)

        const csvMenuItem = {
            name: 'CSV Export',
            action: () => {
                gridRef.current!.api.exportDataAsCsv({
                    fileName: fileName,
                    sheetName: fileName,
                    columnKeys: exportColumnKeys,
                    ...(handleFormatExportCells && {
                        processCellCallback: handleFormatExportCells,
                    }),
                    ...(handleSkipExportRow && {
                        shouldRowBeSkipped: handleSkipExportRow,
                    }),
                })
            },
            icon: '<i class="fa fa-file-csv-ag-grid"/>',
        }

        const excelMenuItem = {
            name: 'Excel Export',
            action: () => {
                gridRef.current!.api.exportDataAsExcel({
                    fileName: fileName,
                    sheetName: fileName,
                    columnKeys: exportColumnKeys,
                    ...(handleFormatExportCells && {
                        processCellCallback: handleFormatExportCells,
                    }),
                    ...(handleSkipExportRow && {
                        shouldRowBeSkipped: handleSkipExportRow,
                    }),
                })
            },
            icon: '<i class="fa fa-file-excel-ag-grid"/>',
        }

        if (Boolean(masterDetailType)) {
            return [
                collapseAllMenuItem,
                resetMenuItem,
                'separator',
                'copy',
                csvMenuItem,
                excelMenuItem,
            ]
        }
        return [resetMenuItem, 'separator', 'copy', csvMenuItem, excelMenuItem]

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [masterDetailType])

    const sideToolPanel = useMemo<
        SideBarDef | string | string[] | boolean | null
    >(() => {
        const filterColumnsSidebar = {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
            },
        }

        const midDetailsSidebar = {
            id: 'midDetails',
            labelDefault: 'MID Details',
            labelKey: 'midDetails',
            iconKey: 'menu',
            width: 350,
            minWidth: 350,
            maxWidth: 350,
            toolPanel: MerchantMidsGridDetails,
            toolPanelParams: {
                selectedRowData: sidebarDetails?.selectedRowData,
                availableDeflectors: sidebarDetails?.availableDeflectors,
                handleMidsListEditMid: sidebarDetails?.handleMidsListEditMid,
            },
        }

        if (gridName === 'mids') {
            return {
                toolPanels: [midDetailsSidebar],
                defaultToolPanel: '',
            }
        }

        return {
            toolPanels: [
                filterColumnsSidebar,
                // {
                //     id: 'filters',
                //     labelDefault: 'Filters',
                //     labelKey: 'filters',
                //     iconKey: 'filter',
                //     toolPanel: 'agFiltersToolPanel',
                // },
            ],
            defaultToolPanel: '',
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridName, sidebarDetails])

    const setColumnAndFilterState = () => {
        if (gridRef.current) {
            const colState = localStorage.getItem(`col-${gridName}`)
            const filterState = localStorage.getItem(`filter-${gridName}`)

            if (autoSizeColumnList.length) {
                gridRef.current.columnApi.autoSizeColumns(
                    autoSizeColumnList,
                    false
                )
            }

            if (colState && colState !== 'undefined') {
                gridRef.current!.columnApi.applyColumnState({
                    state: JSON.parse(colState),
                    applyOrder: true,
                })
            }
            if (filterState && filterState !== 'undefined') {
                gridRef.current!.api.setFilterModel(JSON.parse(filterState))
            }
        }
    }

    const onFirstDataRendered = useCallback(() => {
        setColumnAndFilterState()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSaveGridColumnState = () => {
        if(!enableSaveGridColumnFilterState) return
        if (!gridRef.current) return

        const colState = gridRef.current!.columnApi?.getColumnState()
        if (colState && typeof colState === 'object') {
            localStorage.setItem(`col-${gridName}`, JSON.stringify(colState))
        }
    }

    const onSaveGridFilterState = () => {
        if(!enableSaveGridColumnFilterState) return
        if (!gridRef.current) return

        const filterState = gridRef.current!.api?.getFilterModel()
        if (filterState && typeof filterState === 'object') {
            localStorage.setItem(
                `filter-${gridName}`,
                JSON.stringify(filterState)
            )
        }
    }

    const onSelectionChanged = (params: SelectionChangedEvent) => {
        const selectedNodesLength = params.api.getSelectedNodes().length
        const renderedNodesLength = params.api.getDisplayedRowCount()
        if (selectedNodesLength !== renderedNodesLength) {
            setIsAllChecked(false)
            handleSelectAllCheckbox(false)
        }

        handleSelectionChanged()
    }

    useEffect(() => {
        if (gridRef.current && gridRef.current.api) {
            if (loading || enableLoadingOverlay)
                gridRef.current!.api.showLoadingOverlay()
            if (!loading && !totalEntries)
                gridRef.current!.api.showNoRowsOverlay()

            gridRef.current!.api.deselectAll()
            setIsAllChecked(false)
            handleSelectAllCheckbox(false)

            if (selectedRowIndex !== null) {
                return gridRef
                    .current!.api.getDisplayedRowAtIndex(selectedRowIndex)
                    ?.selectThisNode(true)
            }
            if (enableFirstRowSelection) {
                return gridRef.current.api
                    .getDisplayedRowAtIndex(0)
                    ?.selectThisNode(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, selectedRowIndex, enableLoadingOverlay])

    const onGridReady = (params: GridReadyEvent) => {
        params.api!.setServerSideDatasource(dataSource)
        setColumnAndFilterState()

        // Add id attributes to grid for testing:
        const gridHeaderContainer = document.querySelector('.ag-header')
        gridHeaderContainer?.setAttribute(
            'id',
            `column-header-container-${testId}`
        )
        const gridBodyContainer = document.querySelector('.ag-body-viewport')
        gridBodyContainer?.setAttribute('id', `grid-data-container-${testId}`)
    }

    const refreshAGGrid = () => {
        const api = gridRef?.current!?.api!
        if (api) {
            api.refreshServerSide()
            api.showLoadingOverlay()
        }
    }

    return (
        <Box
            className={`ag-theme-alpine ${className} ${
                !enableDataGridTopBorder && 'emp-agDataGrid-noTopBorder'
            } ${
                !ZEBRA_STRIPED_ROWS?.enabled && 'emp-agDataGrid-noZebraStriping'
            } ${
                enableAutoVerticalScroll && 'emp-agDataGrid-autoVerticalScroll'
            }`}
            {...(testId && {
                id: `${idDirectory.agDataGrid.divRoot}-${testId}`,
            })}
            //@ts-ignore
            sx={() => gridTheme.style}
        >
            {enableEmptyDataGrid || !columns.length ? (
                <AgGridReact
                    key={'key-1'}
                    ref={gridRef}
                    rowData={[]}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColumnDef}
                    animateRows={true}
                    rowHeight={rowHeight ? rowHeight : gridTheme.rowHeight}
                    headerHeight={
                        headerHeight ? headerHeight : gridTheme.headerHeight
                    }
                    domLayout={'autoHeight'}
                    alwaysShowVerticalScroll={true}
                    alwaysShowHorizontalScroll={alwaysShowHorizontalScroll}
                    maintainColumnOrder
                    tooltipShowDelay={500}
                    onFirstDataRendered={onFirstDataRendered}
                    getMainMenuItems={getColumnMenuItems}
                    loadingCellRenderer={false}
                    // Props for Overlays:
                    loadingOverlayComponent={loadingOverlayComponent}
                    overlayNoRowsTemplate={
                        emptyDataGridOverlay
                            ? emptyDataGridOverlay
                            : overlayNoRowsTemplate
                    }
                    // Props for context menu feature:
                    getContextMenuItems={(params) =>
                        enableCustomContextMenuItems
                            ? getCustomContextMenuItems(params)
                            : getDefaultContextMenuItems()
                    }
                    // Props for side tool panel:
                    sideBar={enableSideBarPanel ? sideToolPanel : false}
                    // Used to save column and filter state in local storage:
                    onSortChanged={onSaveGridColumnState}
                    onColumnVisible={onSaveGridColumnState}
                    onColumnPinned={onSaveGridColumnState}
                    onColumnResized={onSaveGridColumnState}
                    onColumnMoved={onSaveGridColumnState}
                    onColumnRowGroupChanged={onSaveGridColumnState}
                    onColumnValueChanged={onSaveGridColumnState}
                    onColumnPivotChanged={onSaveGridColumnState}
                    onFilterChanged={onSaveGridFilterState}
                />
            ) : (
                <AgGridReact
                    key={'key-2'}
                    ref={gridRef}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColumnDef}
                    animateRows={true}
                    rowHeight={rowHeight ? rowHeight : gridTheme.rowHeight}
                    headerHeight={
                        headerHeight ? headerHeight : gridTheme.headerHeight
                    }
                    domLayout={'autoHeight'}
                    alwaysShowVerticalScroll={true}
                    alwaysShowHorizontalScroll={alwaysShowHorizontalScroll}
                    maintainColumnOrder
                    tooltipShowDelay={500}
                    onFirstDataRendered={onFirstDataRendered}
                    getMainMenuItems={getColumnMenuItems}
                    suppressCellFocus={disableCellFocus}
                    loadingCellRenderer={false}
                    // Props for Overlays:
                    loadingOverlayComponent={loadingOverlayComponent}
                    overlayNoRowsTemplate={overlayNoRowsTemplate}
                    // Props for master detail feature:
                    masterDetail={Boolean(masterDetailType)}
                    detailCellRenderer={masterDetailCellRenderer}
                    detailRowHeight={masterDetailRowHeight}
                    // Props for context menu feature:
                    getContextMenuItems={(params) =>
                        enableCustomContextMenuItems
                            ? getCustomContextMenuItems(params)
                            : getDefaultContextMenuItems()
                    }
                    // Props for row selection:
                    rowSelection={rowSelectionType}
                    getRowId={getRowId}
                    suppressRowClickSelection={disableRowClickSelection}
                    onSelectionChanged={onSelectionChanged}
                    isRowSelectable={handleIsRowSelectable}
                    // Props for double clicked cell:
                    onCellDoubleClicked={handleDoubleClickedCell}
                    // Props for clicked row:
                    onRowClicked={handleRowClicked}
                    // Props for formatting cells needed for copy feature:
                    processCellForClipboard={handleFormatExportCells}
                    // Props for side tool panel:
                    sideBar={enableSideBarPanel ? sideToolPanel : false}
                    // Props for server-side data:
                    rowModelType={'serverSide'}
                    onGridReady={onGridReady}
                    serverSideSortOnServer
                    serverSideFilterOnServer
                    // Props for editable cells
                    stopEditingWhenCellsLoseFocus={true}
                    onCellValueChanged={handleCellValueChanged}
                    // Used to save column and filter state in local storage:
                    onSortChanged={onSaveGridColumnState}
                    onColumnVisible={onSaveGridColumnState}
                    onColumnPinned={onSaveGridColumnState}
                    onColumnResized={onSaveGridColumnState}
                    onColumnMoved={onSaveGridColumnState}
                    onColumnRowGroupChanged={onSaveGridColumnState}
                    onColumnValueChanged={onSaveGridColumnState}
                    onColumnPivotChanged={onSaveGridColumnState}
                    onFilterChanged={onSaveGridFilterState}
                />
            )}
            {dataGridBottomBar && (
                <div
                    {...(testId && {
                        id: `${idDirectory.agDataGrid.divBottomBar}-${testId}`,
                    })}
                    className="ag-bottomBar-container emp-agDataGrid-bottomBarContainer"
                >
                    {dataGridBottomBar}
                </div>
            )}
            {paginate && (
                <div
                    {...(testId && {
                        id: `${idDirectory.agDataGrid.divPagination}-${testId}`,
                    })}
                    className="ag-pagination-container"
                >
                    <PaginationAGGrid
                        pageCount={
                            enableEmptyDataGrid
                                ? 0
                                : Math.ceil(totalEntries / pageSize)
                        }
                        pageSize={pageSize}
                        totalEntries={enableEmptyDataGrid ? 0 : totalEntries}
                        currentPage={page}
                        setCurrentPage={onPageChange}
                        paginationOptions={rowsPerPageOptions}
                        onPageLimitChange={onPageLimitChange}
                        loading={loading}
                        testId={testId}
                        enablePageLimitOptions={enablePageLimitOptions}
                        enablePaginationPageChange={enablePaginationPageChange}
                        enablePaginationRefreshBtn={enablePaginationRefreshBtn}
                        enablePaginationDisplayingText={
                            enablePaginationDisplayingText
                        }
                        handlePaginationRefreshBtn={
                            refreshDataGrid ? refreshDataGrid : refreshAGGrid
                        }
                        enablePaginationBorder={gridTheme.paginationBorder}
                        paginationAlertContent={paginationAlertContent}
                        disablePaginationBtns={enableEmptyDataGrid}
                    />
                </div>
            )}
        </Box>
    )
}

export default AGDataGrid
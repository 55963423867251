import React, { useState } from 'react'
import { useFilterAlerts } from '../useFilterAlerts'
import { PresetDateRange, SearchableSelect } from 'components'
import { useSWRContext } from 'context/SWRContext/SWRContext'
import idDirectory from '../idAttributes'
import { defaultPostDateRange } from '../Alerts.vm'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

interface FilterAlertsProps {
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

type Option = {
    id: number
} & Record<string, unknown>

interface SelectInputProps {
    inputOptionAccessor: string
    optionList: Option[]
    placeholder?: string
    [key: string]: any
}

const SelectInput = ({
    inputOptionAccessor,
    optionList,
    handleSearch,
    searchValue,
    setFilters,
}: SelectInputProps) => {
    const [inputValue, setInputValue] = useState<string>('')

    const handleChange = (val: Option | null) => {
        // checks if All Descriptors is selected, if so, this will send an empty string as a search value to reset alerts query to all descriptos
        if (val?.name === 'All Descriptors') {
            setInputValue('All Descriptors')
            setFilters('')
        } else {
            setInputValue(val?.name as string)
            setFilters(val?.name as string)
        }
    }

    const value = React.useMemo(() => {
        // returns first option in list array if no search value
        if (!inputValue) return optionList[0][inputOptionAccessor] as string
        else return inputValue
        // eslint-disable-next-line
    }, [inputValue])

    return (
        <div
            id={idDirectory.filterAlerts.divDescriptors}
            className={'emp-inputContainer'}
        >
            <SearchableSelect
                value={value}
                onValueChange={handleChange}
                options={optionList ?? []}
                searchValue={searchValue}
                onSearchChange={handleSearch}
                accessor={inputOptionAccessor}
            />
        </div>
    )
}

const FilterAlerts = ({
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}: FilterAlertsProps) => {
    const { descriptorOptions, handleSearch, searchValue } = useFilterAlerts(
        setAlertSnackbarMainProps,
        setAlertSnackbarMainOpen,
    )
    const { swrActions } = useSWRContext()

    const { setDescriptorFilter, setDateRange } = swrActions

    return (
        <div id={'preChargebacksFilterAlerts'}>
            <div className={'emp-filterSortContainer'}>
                <label>Filter By: </label>
                <SelectInput
                    setFilters={setDescriptorFilter}
                    placeholder="All Descriptors"
                    inputOptionAccessor="name"
                    optionList={descriptorOptions}
                    handleSearch={handleSearch}
                    searchValue={searchValue}
                />
                <PresetDateRange
                    getDateRange={setDateRange}
                    dateRange={defaultPostDateRange}
                    className={'emp-dateRangePicker'}
                    testId="filterAlerts"
                    allowFutureDateSelection={false}
                />
            </div>
        </div>
    )
}

export default FilterAlerts

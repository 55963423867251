import React, { useState } from 'react'
import { useAlertsVm } from './Alerts.vm'
import idDirectory from './idAttributes'
import {
    // Button,
    Divider,
} from '@mui/material'
import { AccentArea, MerchantSwitcher, AlertSnackbar } from 'components'
import View from 'views/View'
import AlertsGrid from './grids/AlertsGrid'
import FilterAlerts from './actions/FilterAlerts'
import { withRequiredRole } from 'components/useRequireRole'
import EntFeature from 'models/EntFeature'
import { SWRContextProvider as AlertsContextProvider } from 'context/SWRContext/SWRContext'
// import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { AlertsAutoRefundDialog } from './dialogs/AlertsAutoRefundDialog'

const PreChargebacks = () => {
    const {
        data,
        swrActions,
        isSelfService,
        downloadXlsx,
        isLoadingDownloadXlsxAll,
        setCurrentAlertId,
        currentAlertId,
        toggleModal,
        alertHistoryOpen,
        markOutcomeOpen,
        completeOpen,
        selectMIDOpen,
        midList,
        setSelectedMid,
        alertsOutcomesData,
        selectedAlertInfo,
        params,
        alertSnackbarMainOpen,
        setAlertSnackbarMainOpen,
        alertSnackbarMainProps,
        setAlertSnackbarMainProps,
        closeAlertSnackbar,
    } = useAlertsVm()
    // const { AUTO_REFUND_RULES } = useFeatureToggle('ALERTS')
    const [alertsAutoRefundOpen, setAlertsAutoRefundOpen] = useState(false)
    
    return (
        <AlertsContextProvider SWRActions={swrActions}>
            <View
                title="Pre-Chargebacks"
                breadcrumb="Pre-Chargebacks"
                HeaderInterior={<MerchantSwitcher testId="pre-chargebacks" />}
                testId="pre-chargebacks"
            >
                <div id={idDirectory.divRoot}>
                    <AccentArea
                        containsFullWidthGrid={true}
                        testId="pre-chargebacks"
                        hasHeaderDivider={false}
                    >
                        <div className={'emp-headerContent'}>
                            <div>
                                <FilterAlerts
                                    setAlertSnackbarMainProps={
                                        setAlertSnackbarMainProps
                                    }
                                    setAlertSnackbarMainOpen={
                                        setAlertSnackbarMainOpen
                                    }
                                />
                            </div>

                            {/* {isSelfService && (
                                <div className={'emp-header}>
                                    <Button
                                        variant="contained"
                                        onClick={() =>
                                            setAlertsAutoRefundOpen(true)
                                        }
                                        size="small"
                                        color="secondary"
                                        style={{ textTransform: 'capitalize' }}
                                        disabled={!AUTO_REFUND_RULES.enabled}
                                    >
                                        Auto Refund Rules
                                    </Button>
                                </div>
                            )} */}
                        </div>
                        <Divider className={'emp-divider'} />
                        <div>
                            <AlertsGrid
                                data={data}
                                testId="pre-chargebacks"
                                downloadXlsx={downloadXlsx}
                                isLoadingDownloadXlsxAll={
                                    isLoadingDownloadXlsxAll
                                }
                                setCurrentAlertId={setCurrentAlertId}
                                currentAlertId={currentAlertId}
                                toggleModal={toggleModal}
                                alertHistoryOpen={alertHistoryOpen}
                                markOutcomeOpen={markOutcomeOpen}
                                selectMIDOpen={selectMIDOpen}
                                selectedAlertInfo={selectedAlertInfo}
                                completeOpen={completeOpen}
                                midList={midList}
                                setSelectedMid={setSelectedMid}
                                isSelfService={isSelfService}
                                alertsOutcomesData={alertsOutcomesData}
                                params={params}
                                setAlertSnackbarMainProps={
                                    setAlertSnackbarMainProps
                                }
                                setAlertSnackbarMainOpen={
                                    setAlertSnackbarMainOpen
                                }
                            />
                        </div>
                    </AccentArea>
                </div>
                <AlertsAutoRefundDialog
                    open={alertsAutoRefundOpen}
                    onClose={() => setAlertsAutoRefundOpen(false)}
                />
                <AlertSnackbar
                    content={alertSnackbarMainProps}
                    onClose={closeAlertSnackbar}
                    open={alertSnackbarMainOpen}
                    showCloseIcon
                    autoHideDuration={
                        alertSnackbarMainProps.title === 'Export started.'
                            ? 30_000
                            : 3_000
                    }
                />
            </View>
        </AlertsContextProvider>
    )
}

export default withRequiredRole(EntFeature.PRE_CHARGEBACKS, PreChargebacks)

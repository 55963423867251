import { useState, useEffect } from 'react'
import { useActiveMerchant } from 'components'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import {
    Alert,
    useSWRActions,
    SWRActions,
    useSwrData,
    useParams,
    AlertsServiceLevel,
} from 'hooks/cb-api'
import { dateUtilities } from 'utils/dateUtilities'
import { IDateRange } from 'components/PresetDateRange'
import { format } from 'date-fns'
import axios from 'axios'
import { DownloadParams } from 'lib/Cases'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

export type Download = (params: DownloadParams) => Promise<unknown>

export type ModalVariant = 'complete' | 'mark' | 'history' | 'selectMid'

export interface MidList {
    name: string
    value: string
    id: string
    descriptor: string
}

interface AlertsVM {
    data: Alert[]
    serviceLevelData: AlertsServiceLevel
    isSelfService: boolean
    swrActions: SWRActions
    error: any
    downloadXlsx: Download
    isLoadingDownloadXlsxAll: boolean
    downloadExportAll: Download
    setCurrentAlertId: (id: number | undefined) => void
    currentAlertId: number | undefined
    toggleModal: (variant: ModalVariant) => void
    alertHistoryOpen: boolean
    completeOpen: boolean
    markOutcomeOpen: boolean
    selectMIDOpen: boolean
    alertsOutcomesData: any
    midList: MidList[]
    setSelectedMid: (mid: MidList) => void
    selectedAlertInfo: Alert
    params: any
    alertSnackbarMainOpen: boolean
    setAlertSnackbarMainOpen: (status: boolean) => void
    alertSnackbarMainProps: alertSnackbarContentProps
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    closeAlertSnackbar: () => void
}

const defaultPostDateeRangeOnLoad: {
    to: Date
    from: Date
} = dateUtilities.getPriorDays(7)
const { to, from } = defaultPostDateeRangeOnLoad
export let defaultPostDateRange: IDateRange = {
    to: format(to, 'yyyy-MM-dd'),
    from: format(from, 'yyyy-MM-dd'),
}
export const defaultDateRange = `${defaultPostDateRange.from}|${defaultPostDateRange.to}`

export const useAlertsVm = (): AlertsVM => {
    const { id: merchantId } = useActiveMerchant()
    const { user } = useAuthedUser()
    const { params, setParams, setQueryParams, reset } = useParams({
        date_range: defaultDateRange,
    })
    const [alertSnackbarMainOpen, setAlertSnackbarMainOpen] = useState<boolean>(
        false
    )
    const [
        alertSnackbarMainProps,
        setAlertSnackbarMainProps,
    ] = useState<alertSnackbarContentProps>({})
    const [midList, setMidList] = useState<MidList[] | []>([])
    const [selectedAlertInfo, setSelectedAlertInfo] = useState<any>({})
    const [
        isLoadingDownloadXlsxAll,
        setIsLoadingDownloadXlsxAll,
    ] = useState<boolean>(false)

    const endpointConfig = {
        paramInUrl: false,
        url: 'cm/svc/alerts',
        id: {
            key: 'merchant_id',
            value: merchantId,
        },
    }

    const alertsData = useSwrData(endpointConfig, params)

    const swrActions = useSWRActions(alertsData, {
        setParams,
        params,
        setQueryParams,
        reset,
    })

    const endpointConfigServiceLevel = {
        paramInUrl: false,
        url: '/cm/svc/alerts/service-level',
        id: {
            key: 'merchant_id',
            value: merchantId,
        },
    }

    const alertsServiceLevelData = useSwrData(endpointConfigServiceLevel)

    const endpointAlertsOutcomesData = {
        paramInUrl: false,
        url: '/cm/svc/alerts/outcome/dropdown',
        id: {
            key: 'merchant_id',
            value: merchantId,
        },
    }

    const alertsOutcomesData = useSwrData(endpointAlertsOutcomesData)

    // Begin modal handling
    const [currentAlertId, setCurrentAlertId] = useState<number | undefined>(
        undefined
    )
    const [alertHistoryOpen, setAlertHistoryOpen] = useState<boolean>(false)
    const [markOutcomeOpen, setMarkOutcomeOpen] = useState<boolean>(false)
    const [completeOpen, setCompleteOpen] = useState<boolean>(false)
    const [selectMIDOpen, setSelectMIDOpen] = useState<boolean>(false)

    const toggleModal = (variant: ModalVariant) => {
        switch (variant) {
            case 'complete':
                setCompleteOpen((prev) => !prev)
                break
            case 'mark':
                setMarkOutcomeOpen((prev) => !prev)
                break
            case 'history':
                setAlertHistoryOpen((prev) => !prev)
                break
            case 'selectMid':
                setSelectMIDOpen((prev) => !prev)
                break
            default:
                console.log('Unknown modal type')
                break
        }
    }
    // End modal handling

    useEffect(() => {
        if (!currentAlertId || !alertsData.data.length) return
        const currentAlertData = alertsData.data.find((data: any) => {
            return data.alert_id === currentAlertId
        })
        setSelectedAlertInfo(currentAlertData || {})
    }, [currentAlertId, alertsData])

    //get MID list for select MIDs
    useEffect(() => {
        if (!merchantId) return
        axios
            .get(`/cm/svc/alerts/descriptors/mapping`, {
                params: {
                    merchant_id: merchantId,
                },
            })
            .then((data) => {
                const midOptions = data.data.data.map((item: any) => {
                    return {
                        name: item.mid,
                        value: item.mid,
                        id: item.mid_id,
                        descriptor: item.descriptor,
                    }
                })
                setMidList(midOptions)
            })
            .catch((e) =>
                setMidList([{ name: '', value: '', id: '', descriptor: '' }])
            )
    }, [merchantId])

    const setSelectedMid = (item: MidList) => {
        // Due to a mapping issue beyond out control, the endpoint to retrieve the list of MIDs only
        // contains descriptor IDs. We need to send the human readable descriptor name however. After a
        // discussion with the back end team, we are going to get the descriptor data from the currently
        // selected alert row for now.

        const selectedAlertData = alertsData.data.find(
            (alert: any) => alert.alert_id === currentAlertId
        )
        if (!selectedAlertData) return Promise.reject()

        return axios.post(
            `/cm/svc/alerts/descriptors/mapping?merchant_id=${merchantId}`,
            {
                client_id: +merchantId,
                mid_id: +item.id,
                descriptor: selectedAlertData.descriptor,
                user_id: Number(user?.id),
            }
        )
    }

    //todo: update for Export All (csv)
    const downloadExportAll: Download = async (P: DownloadParams) => {
        try {
            const response = await axios.get(`/cm/svc/alerts.csv`, {
                responseType: 'blob',
                params: {
                    ...P,
                    merchant_id: merchantId,
                    sort_order: 'asc',
                },
                headers: {
                    'Content-Type': 'text/csv',
                },
            })
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'alerts.csv')
            document.body.appendChild(link)
            link.click()
            link.remove()
            return
        } catch (error) {
            setAlertSnackbarMainProps({
                title: 'Error',
                message: 'An unknown error has occurred.',
                intent: 'error',
            })
            setAlertSnackbarMainOpen(true)
        }
    }

    const downloadXlsx: Download = async (
        P: DownloadParams,
        isSinglePage?: boolean
    ) => {
        !isSinglePage && setIsLoadingDownloadXlsxAll(true)

        try {
            const response = await axios.get(`/cm/svc/alerts.xlsx`, {
                responseType: 'blob',
                params: {
                    ...P,
                    sort_order: 'asc',
                    merchant_id: merchantId,
                },
                headers: {
                    'Content-Type':
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
            })
            if (
                [
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'text/csv',
                ].includes(response.data.type) ||
                isSinglePage
            ) {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                )
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'alerts.xlsx')
                document.body.appendChild(link)
                link.click()
                link.remove()
            } else {
                setAlertSnackbarMainProps({
                    message:
                        'This may take up to 15 minutes. We will send you an email with a link to download your export when it’s ready. Thank you!',
                    title: 'Export started.',
                    intent: 'success',
                })
                setAlertSnackbarMainOpen(true)
            }
            return
        } catch (error) {
            setAlertSnackbarMainProps({
                title: 'Error',
                message: 'An unknown error has occurred.',
                intent: 'error',
            })
            setAlertSnackbarMainOpen(true)
        } finally {
            !isSinglePage && setIsLoadingDownloadXlsxAll(false)
        }
    }

    const closeAlertSnackbar = () => setAlertSnackbarMainOpen(false)

    return {
        data: alertsData.data || [],
        serviceLevelData: alertsServiceLevelData.data || {},
        isSelfService:
            Number(alertsServiceLevelData.data?.service_level_id) === 2 ??
            false,
        swrActions: swrActions,
        error: alertsData.error,
        downloadXlsx,
        isLoadingDownloadXlsxAll,
        downloadExportAll,
        setCurrentAlertId,
        currentAlertId,
        toggleModal,
        alertHistoryOpen,
        markOutcomeOpen,
        completeOpen,
        selectMIDOpen,
        alertsOutcomesData,
        midList: midList ?? [],
        setSelectedMid,
        selectedAlertInfo,
        params,
        alertSnackbarMainOpen,
        setAlertSnackbarMainOpen,
        alertSnackbarMainProps,
        setAlertSnackbarMainProps,
        closeAlertSnackbar,
    }
}

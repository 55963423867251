import React from 'react'
import { Link, Tooltip } from '@mui/material'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { ModalVariant } from '../../Alerts.vm'

interface SelectMidCellProps {
    alertsModal: any
    setCurrentAlertId: (value: number) => void
    toggleModal: (value: ModalVariant) => void
}

const SelectMidCell = ({
    alertsModal,
    setCurrentAlertId,
    toggleModal,
}: SelectMidCellProps) => {
    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only

    return (
        <div id={'preChargebacksAlertsSelectMidCell'}>
            <Tooltip arrow placement="top" title="Select MID">
                {isReadOnly ? (
                    <span>Select MID (disabled)</span>
                ) : (
                    <Link
                        onClick={() => {
                            toggleModal('selectMid')
                            setCurrentAlertId(alertsModal.alert_id)
                        }}
                        className={
                            'emp-link'
                        }
                    >
                        Select MID
                    </Link>
                )}
            </Tooltip>
        </div>
    )
}

export default SelectMidCell

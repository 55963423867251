import {
    CreditCardProcessorType,
    DataGridActions,
    DataGridSlim,
    Searchbar,
    VerdictType,
} from 'components'
import { ExtendedColumn } from 'components/DataGrid/ExtendedColumn'
import SortComponent from 'components/DataGrid/SortComponent'
import React, { useMemo, useState } from 'react'
import { Alert, OutcomeCellValue } from 'hooks/cb-api/types'
import { OutcomeCell, ActionsCell, SelectMidCell } from './cells'
import { useSWRContext } from 'context/SWRContext'
import { ModalVariant, Download, MidList } from '../Alerts.vm'
import AlertHistory from '../modals/AlertHistory'
import CompleteAlert from '../modals/CompleteAlert'
import MarkAlert from '../modals/MarkAlert'
import SelectMID from '../modals/SelectMID'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

interface IAlertsGridProps {
    data: Alert[]
    testId: string
    downloadXlsx: Download
    isLoadingDownloadXlsxAll: boolean
    setCurrentAlertId: (val: number | undefined) => void
    currentAlertId: number | undefined
    toggleModal: (variant: ModalVariant) => void
    alertHistoryOpen: boolean
    markOutcomeOpen: boolean
    completeOpen: boolean
    selectMIDOpen: boolean
    midList: MidList[]
    setSelectedMid: (mid: MidList) => void
    isSelfService: boolean
    alertsOutcomesData: any
    selectedAlertInfo: any
    params?: any
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

export type CloseAndInvalidate = (
    name: ModalVariant,
    shouldInvalidate?: boolean
) => void

const AlertsGrid = ({
    data,
    testId,
    downloadXlsx,
    isLoadingDownloadXlsxAll,
    setCurrentAlertId,
    currentAlertId,
    toggleModal,
    alertHistoryOpen,
    markOutcomeOpen,
    completeOpen,
    selectMIDOpen,
    midList,
    setSelectedMid,
    isSelfService,
    alertsOutcomesData,
    selectedAlertInfo,
    params,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}: IAlertsGridProps) => {
    const { swrActions } = useSWRContext()
    const { invalidate } = swrActions

    const {
        isLoading,
        setPage,
        setRowsPerPage,
        total,
        page,
        setGlobalSearch,
        rowsPerPage,
        setSortBy,
        sortInfo,
        invalidate: rehydrateView,
    } = swrActions

    const closeAndInvalidate: CloseAndInvalidate = (name, shouldInvalidate) => {
        if (shouldInvalidate) invalidate()
        toggleModal(name)
    }

    const columnsFullService: ExtendedColumn[] = useMemo(
        () => [
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="account"
                        value="Account"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'account',
                priority: 3,
                width: 106,
                type: 'string',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="alert_id"
                        value="Alert ID"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'alert_id',
                priority: 4,
                width: 101,
                type: 'string',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="source"
                        value="Source"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'source',
                priority: 5,
                width: 106,
                type: 'string',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="order_id"
                        value="Order ID"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'order_id',
                priority: 6,
                width: 123,
                type: 'string',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="trans_amount"
                        value="Trans Amt"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'trans_amount',
                priority: 7,
                width: 121,
                type: 'number',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="currency"
                        value="Currency"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'currency',
                priority: 8,
                width: 113,
                type: 'string',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="trans_date"
                        value="Trans. Date"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'trans_date',
                priority: 9,
                width: 129,
                type: 'string',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="cc_num"
                        value="CC #"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'cc_num',
                priority: 10,
                width: 139,
                type: 'string',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="cc_type"
                        value="CC Type"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'cc_type',
                Cell: ({ value }: { value: any }) => (
                    <CreditCardProcessorType processor={value} size="large" />
                ),
                priority: 11,
                width: 105,
                type: 'icon',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="descriptor"
                        value="Descriptor"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'descriptor',
                priority: 12,
                width: 231,
                type: 'string',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="mid"
                        value="MID"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'mid',
                priority: 13,
                width: 138,
                type: 'string',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="alert_date"
                        value="Alert Date"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'alert_date',
                priority: 2,
                width: 155,
                type: 'string',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="outcome"
                        value="Outcome"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'outcome',
                Cell: ({ value }: { value: OutcomeCellValue }) => (
                    <OutcomeCell value={value} />
                ),
                priority: 1,
                width: 103,
                type: 'icon',
            },
        ],
        [setSortBy, sortInfo]
    )

    const columnsSelfService: ExtendedColumn[] = useMemo(
        () => [
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="status"
                        value="Status"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'status',
                Cell: (cell: any) => {
                    return (
                        <VerdictType
                            verdict={cell.row.original.outcome}
                            size="small"
                            label={'status'}
                        />
                    )
                },
                priority: 4,
                width: 91,
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="account"
                        value="Account"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'account',
                priority: 5,
                width: 106,
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="alert_id"
                        value="Alert ID"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'alert_id',
                priority: 6,
                width: 101,
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="source"
                        value="Source"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'source',
                priority: 7,
                width: 106,
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="order_id"
                        value="Order ID"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'order_id',
                priority: 8,
                width: 123,
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="trans_amount"
                        value="Trans Amt"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'trans_amount',
                priority: 9,
                width: 121,
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="currency"
                        value="Currency"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'currency',
                priority: 10,
                width: 113,
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="trans_date"
                        value="Trans. Date"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'trans_date',
                priority: 11,
                width: 129,
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="cc_num"
                        value="CC #"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'cc_num',
                priority: 12,
                width: 139,
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="cc_type"
                        value="CC Type"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'cc_type',
                Cell: ({ value }: { value: any }) => (
                    <CreditCardProcessorType processor={value} size="large" />
                ),
                priority: 13,
                width: 105,
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="descriptor"
                        value="Descriptor"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'descriptor',
                priority: 14,
                width: 231,
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="mid"
                        value="MID"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'mid',
                Cell: ({ row }: any) =>
                    row.original.mid === '' || row.original.mid === null ? (
                        <SelectMidCell
                            alertsModal={row.original}
                            setCurrentAlertId={setCurrentAlertId}
                            toggleModal={toggleModal}
                        />
                    ) : (
                        row.original.mid
                    ),
                priority: 15,
                width: 138,
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="alert_date"
                        value="Alert Date"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'alert_date',
                priority: 3,
                width: 155,
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="expiration_date"
                        value="Expiration Date"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'expiration_date',
                priority: 16,
                width: 139,
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="completed_date"
                        value="Completed By"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'completed_date',
                priority: 17,
                width: 139,
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={setSortBy}
                        accessor="outcome"
                        value="Outcome"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'outcome',
                Cell: ({ value }: { value: OutcomeCellValue }) => (
                    <OutcomeCell value={value} />
                ),
                priority: 2,
                width: 103,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                Cell: (cell: any) => {
                    return (
                        <ActionsCell
                            alertId={cell.row.original.alert_id}
                            toggleModal={toggleModal}
                            setCurrentAlertId={setCurrentAlertId}
                            value={cell.row.original.outcome}
                            completed={cell.row.original.completed}
                        />
                    )
                },
                priority: 1,
                width: 156,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setSortBy, sortInfo, toggleModal, setCurrentAlertId]
    )

    const columns = isSelfService ? columnsSelfService : columnsFullService

    const [hiddenColumns, setHiddenColumns] = useState([])

    return (
        <div id={"prechargbacksAlertsGrid"}>
            <div
                className={'emp-searchbarContainer'}
            >
                <Searchbar
                    className={
                        'emp-cssOutlinedInput'
                    }
                    debounceDelay={200}
                    returnValue={setGlobalSearch}
                    testId={testId}
                />
                <DataGridActions
                    printId="alerts-dg"
                    tableData={data}
                    dataForExportAll={data}
                    columns={columns}
                    hiddenColumns={hiddenColumns}
                    setHiddenColumns={setHiddenColumns}
                    testId={testId}
                    dataActionExcel={false}
                    dataActionExportAll={false}
                    dataActionExportAllExcel={true}
                    dataActionPDF={true}
                    onExportAllXlsx={() =>
                        downloadXlsx({ export_total: total, ...params })
                    }
                    onExportAllXlsxLoading={isLoadingDownloadXlsxAll}
                    // dataActionSwitchGrids={true}
                    gridName="alerts"
                    fileName={'Alerts'}
                />
            </div>
            <DataGridSlim
                columns={columns}
                data={data}
                defaultRows={25}
                rowsPerPageOptions={[10, 25, 50, 100]}
                loading={isLoading}
                onPageChange={setPage}
                onPageLimitChange={setRowsPerPage}
                totalEntries={total}
                pageSize={rowsPerPage}
                hiddenColumns={hiddenColumns}
                hiddenColumnsEnabled={true}
                stickyHeaders={true}
                expandable={true}
                slim={false}
                contextMenu
                page={page}
                fixedLoadingIndicator={true}
                noSelection
                testId={testId}
                refreshDataGrid={() => rehydrateView()}
            />
            <AlertHistory
                alertId={currentAlertId}
                open={alertHistoryOpen}
                onClose={closeAndInvalidate}
            />
            <CompleteAlert
                alertId={currentAlertId}
                open={completeOpen}
                onClose={closeAndInvalidate}
                setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
            />
            <MarkAlert
                alertId={currentAlertId}
                open={markOutcomeOpen}
                onClose={closeAndInvalidate}
                alertsOutcomesData={alertsOutcomesData}
                setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
            />
            <SelectMID
                selectedAlertInfo={selectedAlertInfo}
                open={selectMIDOpen}
                onClose={closeAndInvalidate}
                midList={midList}
                setSelectedMid={setSelectedMid}
                setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
            />
        </div>
    )
}

export default AlertsGrid

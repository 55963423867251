import { useEffect, useState } from 'react'
import CB from 'lib'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

interface DocumentInformation {
    case_comments?: string
    link?: string
} 

interface CaseQCReviewVM {
    documentInfo: DocumentInformation
    uploadModalOpen: boolean
    caseInfo: any
    alertSnackbarMainOpen: boolean
    setAlertSnackbarMainOpen: (status: boolean) => void
    alertSnackbarMainProps: alertSnackbarContentProps
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    documentLoading: boolean
    // This is just a number that increments by one, with each doc load. Used as a hackish iFrame fix.
    documentLoadKey: number
    loadingCaseInfo: boolean
    showApproveConfirm: boolean
    errorGettingNewCase: boolean
    errorGettingNewCaseText: string
    approveQCReview: () => void
    setUploadModalOpen: (open: boolean) => void
    refreshPDFView: () => void
    goBackToQCReviewGrid: () => void
    setShowApproveConfirm: (open: boolean) => void
    setRefreshCaseInfo: (toggleRefresh: boolean) => void
}

const useCaseQCReview = (): CaseQCReviewVM => {
    const history: any = useHistory()
    const location: any = useLocation()
    const [documentInfo, setDocumentInfo] = useState<DocumentInformation>({})
    const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false)
    const [caseInfo, setCaseInfo] = useState<any>({})
    const [caseId, setCaseId] = useState<number | undefined>(undefined)
    const [alertSnackbarMainOpen, setAlertSnackbarMainOpen] = useState<boolean>(
        false
    )
    const [
        alertSnackbarMainProps,
        setAlertSnackbarMainProps,
    ] = useState<alertSnackbarContentProps>({})
    const [documentLoading, setDocumentLoading] = useState<boolean>(true)
    const [documentLoadKey, setDocumentLoadKey] = useState<number>(0)
    const [loadingCaseInfo, setLoadingCaseInfo] = useState<boolean>(true)
    const [showApproveConfirm, setShowApproveConfirm] = useState<boolean>(false)
    const [errorGettingNewCase, setErrorGettingNewCase] = useState<boolean>(false)
    const [errorGettingNewCaseText, setErrorGettingNewCaseText] = useState<string>('')
    const [refreshCaseInfo, setRefreshCaseInfo] = useState<boolean>(false)

    const {
        setSelectedPatchIDs,
        setSelectedPatchType,
    } = useAuthedUser()

    const goBackToQCReviewGrid = () => {
        history.push({
            pathname: `/case-qc-review`,
            state: {},
        })
    }

    const approveQCReview = async () => {
        if (!caseId) return
        CB.cases
            .approveQCReview(caseId)
            .then(() => {
                setAlertSnackbarMainProps({
                    title: 'Success',
                    message: `Case ${caseId} was successfully approved. Loading next...`,
                    intent: 'success',
                })
                setAlertSnackbarMainOpen(true)
                CB.cases
                    .callQCReviewGet()
                    .then((data: any) => {
                        const nextId = data.data.id
                        if (!nextId) return

                        setCaseId(nextId)

                        CB.cases
                            .callCasePatch(nextId.toString(), 1, 'qc-review')
                            .then(() => {
                                setSelectedPatchIDs &&
                                    setSelectedPatchIDs(nextId)
                                setSelectedPatchType &&
                                    setSelectedPatchType('qc-review')
                                history.push({
                                    pathname: `/case-qc-review/view-case/${nextId}`,
                                    state: {
                                        caseId: nextId,
                                    },
                                })
                            })
                    })
                    .catch((err) => {
                        setDocumentLoading(false)
                        setDocumentInfo({})
                        setCaseInfo({})
                        setCaseId(undefined)
                        setErrorGettingNewCase(true)

                        if (err.response.data.message)
                            return setErrorGettingNewCaseText(
                                err.response.data.message
                            )

                        setErrorGettingNewCaseText(
                            'An unknown error has occurred.'
                        )
                    })
            })
            .catch((err: any) => {
                setAlertSnackbarMainProps({
                    title: 'Error',
                    message: 'An error occurred processing this request.',
                    intent: 'error',
                })
                setAlertSnackbarMainOpen(true)
            })
            .finally(() => {
                setShowApproveConfirm(false)
            })
    }

    const getDocumentS3URL = (caseId: number) => {
        setDocumentLoading(true)

        CB.documents
            .representment(caseId)
            .then((data) => {
                setDocumentInfo({
                    link: data.link,
                    case_comments: data.case_comments,
                })
            })
            .catch((err: any) => {
                setAlertSnackbarMainProps({
                    title: 'Error',
                    message:
                        'An error occurred retrieving document for this case.',
                    intent: 'error',
                })
                setAlertSnackbarMainOpen(true)
            })
            .finally(() => {
                setDocumentLoadKey(documentLoadKey + 1)
                if (documentLoadKey === 0) {
                    return setDocumentLoading(false)
                }
                setTimeout(() => {
                    setDocumentLoading(false)
                }, 2500)
            })
    }

    const refreshPDFView = () => {
        if (!caseId) return
        getDocumentS3URL(caseId)
    }

    useEffect(() => {
        const stateCaseId = location?.state?.caseId
    
        if (!stateCaseId) return
        setCaseId(stateCaseId)

        CB.cases.callQCReviewGet(stateCaseId).then((data) => {
            setCaseInfo(data.data)
            setLoadingCaseInfo(false)
            getDocumentS3URL(stateCaseId)
        }).catch((err) => {
            setErrorGettingNewCase(true)
            if (err.response.data.message)
                return setErrorGettingNewCaseText(err.response.data.message)
            setErrorGettingNewCaseText('An unknown error has occurred.')
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, refreshCaseInfo])

    return {
        alertSnackbarMainOpen,
        setAlertSnackbarMainOpen,
        alertSnackbarMainProps,
        setAlertSnackbarMainProps,
        documentInfo,
        uploadModalOpen,
        caseInfo,
        documentLoading,
        documentLoadKey,
        loadingCaseInfo,
        showApproveConfirm,
        errorGettingNewCase,
        errorGettingNewCaseText,
        refreshPDFView,
        approveQCReview,
        setUploadModalOpen,
        goBackToQCReviewGrid,
        setShowApproveConfirm,
        setRefreshCaseInfo,
    }
}

export default useCaseQCReview

import axios from 'axios'
import { faUpload, faEdit } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router'
import { useActiveMerchant } from 'components'
import { useEffect, useState } from 'react'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { useUiStateContext } from 'context/UiState/UiStateContext'
import { CaseData, AdvancedSearchValues, SetSortInfo } from 'hooks/cb-api/types'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'
import { formatAdvancedFilterFields } from 'components/AdvancedFilters/AdvancedFilters'

// SWR
import {
    useSWRActions,
    SWRActions,
    useCaseActions,
    useSwrData,
    useParams,
    FormField,
    IAdvancedFilterConfig,
} from 'hooks/cb-api'

type HandleAdvancedSearchSubmit = (
    params: AdvancedSearchValues,
    reload: boolean
) => void

type HandleCaseFilterChange = (params: any) => void

interface BulkActionParams {
    /** indexs of selected rows */
    selectedRows: string[]
}

type THandleBulkAction = (params: BulkActionParams) => void

interface ChargebacksInstance {
    activeMerchantId: number
    cases: CaseData[]
    error: string | null
    advanceSearchFields: FormField[]
    handleAdvancedSearchSubmit: HandleAdvancedSearchSubmit
    // advancedSearchValues: AdvancedSearchValues
    // advancedSearchParams: any
    setSortInfo: SetSortInfo
    contextMenuItems: any
    isUploadOpen: boolean
    closeUpload: () => void
    selectedCaseIds: number[]
    handleCaseFilterChange: HandleCaseFilterChange
    clearSelectedRows: boolean
    rehydrateView: () => void
    // selectAllRowsInStore: () => void
    // unselectAllRowsInStore: () => void
    canViewPartnerCompanyId: boolean
    canViewIssuerDocs: boolean
    canViewOutcomeVerdicts: boolean
    canViewPlatform: boolean
    disableSelectAll: boolean
    swrActions: SWRActions
    [key: string]: any
    metricPresets: any
    setMetricPresets: (value: any) => void
    collapseOpen: boolean
    setCollapseOpen: (isOpen: boolean) => void
    params: any
    isLoadingAdvFilterSelect: boolean
    setIsLoadingAdvFilterSelect: (loading: boolean) => void
    openEditChargebacksModal: boolean
    setOpenEditChargebacksModal: (isOpen: boolean) => void
    isLoadingAdvFilterFields: boolean
    alertSnackbarMainOpen: boolean
    setAlertSnackbarMainOpen: (isOpen: boolean) => void
    alertSnackbarMainProps: alertSnackbarContentProps
    setAlertSnackbarMainProps: (props: alertSnackbarContentProps) => void
}

export const useChargebacks = ({ ...props }: any): ChargebacksInstance => {
    const history = useHistory()
    const { form_field_variant } = useFeatureToggle('CHARGEBACKS')
    const { CHARGEBACKS: advancedSearchFilters } = useFeatureToggle(
        'ADVANCED_SEARCH_FILTERS'
    )
    const { client } = useFeatureToggle('CLIENT')
    const [selectedCaseIds, setSelectedCaseIds] = useState<number[]>([])
    const [openRepresentmentUploadModal, setOpenRepresentmentModal] = useState(
        false
    )
    const [openEditChargebacksModal, setOpenEditChargebacksModal] = useState(
        false
    )

    const uiState = useUiStateContext()

    const { id: merchantId } = useActiveMerchant()
    const [clearSelectedRows, setClearSelectedRows] = useState(false)
    const [didSort, setDidSort] = useState(false)
    const [metricPresets, setMetricPresets] = useState(null)
    const [collapseOpen, setCollapseOpen] = useState(false)
    const [isLoadingAdvFilterSelect, setIsLoadingAdvFilterSelect] = useState(false)
    const [isLoadingAdvFilterFields, setIsLoadingAdvFilterFields] = useState(true)
    const [advFilterFields, setAdvFilterFields] = useState<FormField[]>([])

    const [alertSnackbarMainOpen, setAlertSnackbarMainOpen] = useState<boolean>(
        false
    )
    const [
        alertSnackbarMainProps,
        setAlertSnackbarMainProps,
    ] = useState<alertSnackbarContentProps>({})

    // ** SWR INTEGRATION BELOW
    const { params, setParams, setQueryParams, reset } = useParams({
        sort_order: 'desc',
        sort_by: 'post_date',
    })

    const endpointConfig = {
        paramInUrl: false,
        url: 'cm/cases',
        id: {
            key: 'client_id',
            value: merchantId,
        },
    }

    const chargebacksData = useSwrData(endpointConfig, params)
    const paramSetter = { setParams, setQueryParams, params, reset }

    const swrActions = useSWRActions(chargebacksData, paramSetter)
    const caseActions = useCaseActions(chargebacksData, paramSetter)

    const extendedSwrActions = { ...swrActions, ...caseActions }

    const { mutate, error } = chargebacksData

    const {
        setQueryParams: setQueryParamsSwrInstance,
        caseNetworkActions,
    } = extendedSwrActions

    const {
        submitAdvancedSearchValues,
        clearAdvancedSearchValues,
    } = caseNetworkActions

    // For JPMC and only if cm-view-partner-admin-id in whoami roles.
    const canViewPartnerCompanyId =
        (uiState.whoami?.roles ?? []).filter(
            (role) => role === 'cm-view-partner-admin-id'
        ).length !== 0
            ? true
            : false

    const canViewIssuerDocs =
        uiState.whoami?.merchant?.features?.some(
            (obj: { id: number; name: string }) => obj.id === 1
        ) ?? false

    const canViewOutcomeVerdicts =
        uiState.whoami?.merchant?.features?.some(
            (obj: { id: number; name: string }) => obj.id === 2
        ) ?? false

    const canViewPlatform =
        uiState.whoami?.merchant?.features?.some(
            (obj: { id: number; name: string }) => obj.id === 3
        ) ?? false

    useEffect(() => {
        setIsLoadingAdvFilterFields(true)
        axios
            .get(`/config/cfg/cases_advanced_filters`)
            .then(({ data }) => {
                const formattedData =
                    data?.attr?.filters
                        ?.filter(
                            (field: IAdvancedFilterConfig) => field.is_enabled
                        )
                        ?.map((field: IAdvancedFilterConfig) => ({
                            key: field.alias,
                            filterOverrideName: field.alias_override,
                            name: field.label,
                            initialValue: field.initial_value,
                            initialCompareValue: field.initial_compare_value,
                            type: field.type,
                            apiValuesPath: field.endpoint,
                            defaultValueOptions: field.default_options,
                            disableMultipleSelections: !field.is_multiple_choice,
                            clearDateIcon: field.is_clear_date_icon,
                            hideField: false,
                        })) ?? []
                setAdvFilterFields([
                    ...advancedSearchFilters.filters,
                    ...formattedData,
                ])
            })
            .catch(() => {
                setAlertSnackbarMainProps({
                    title: 'Error',
                    message: `An error occurred while loading the advanced search filters. Please try again later.`,
                    intent: 'error',
                })
                setAlertSnackbarMainOpen(true)
            })
            .finally(() => setIsLoadingAdvFilterFields(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const FORM_FIELDS = () => {
        let filteredAdvancedSearchFilters = formatAdvancedFilterFields(
            advFilterFields
        )
        const foundVerdictFilter
            = filteredAdvancedSearchFilters.find((obj: { key: string }) => obj.key === 'verdict')
        if (foundVerdictFilter && !canViewOutcomeVerdicts) {
            filteredAdvancedSearchFilters
                = filteredAdvancedSearchFilters.filter((obj: { key: string }) => obj.key !== 'verdict')
        }

        if (form_field_variant === 'jpmc' && canViewPartnerCompanyId) {
            return [
                ...filteredAdvancedSearchFilters,
                {
                    key: 'enterprise_company_id',
                    name: 'Enterprise Company ID',
                    initialValue: '',
                    type: 'text',
                    sendArray: true,
                },
            ]
        } else return filteredAdvancedSearchFilters
    }

    useEffect(() => {
        if (props.location.state?.preset_filter && advFilterFields.length) {
            setMetricPresets(props.location.state.preset_filter)
            // setCollapseOpen(true)
        }
    }, [props.location, advFilterFields])

    // check if we have a case error after sorting - didSort is set in the setSortInfo function
    // if the store returns an error after sorting, we set the sortBy accessor to an empty string
    useEffect(() => {
        if (error && didSort) {
            extendedSwrActions.setSortBy(false, '')
            setDidSort(false)
        }
        return () => setDidSort(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    const handleAdvancedSearchSubmit: HandleAdvancedSearchSubmit = (params) =>
        submitAdvancedSearchValues(params, FORM_FIELDS())

    const handleAdvancedSearchClear = () => {
        clearAdvancedSearchValues()
        history.push({
            state: {},
        })
    }

    const setSortInfo: SetSortInfo = (sortInfo) => {
        setDidSort(true)
        extendedSwrActions.setSortInfo({
            sortBy: sortInfo.sortBy,
            sortDesc: sortInfo.sortDesc ? 'asc' : 'desc',
        })
    }

    const handleBulkUpload: THandleBulkAction = () => {
        const caseIds = chargebacksData.data
            .filter((_: any, idx: number) =>
                swrActions.selectedRows.includes(idx.toString())
            )
            .map((i: any) => i.id)
        setSelectedCaseIds(caseIds)
        setOpenRepresentmentModal(true)
    }

    const handleBulkEdit: THandleBulkAction = () => {
        const caseIds = chargebacksData.data
            .filter((_: any, idx: number) =>
                swrActions.selectedRows.includes(idx.toString())
            )
            .map((i: any) => i.id)
        setSelectedCaseIds(caseIds)
        setOpenEditChargebacksModal(true)
    }

    const contextMenuItems =
        client === 'bluesnap'
            ? [
                  {
                      value: 'Bulk Edit',
                      icon: faEdit,
                      operator: handleBulkEdit,
                  },
              ]
            : [
                  {
                      value: 'Bulk Upload',
                      icon: faUpload,
                      operator: handleBulkUpload,
                  },
              ]

    const handleCaseFilterChange: HandleCaseFilterChange = (params) => {
        setQueryParamsSwrInstance(params)
    }

    const rehydrateView = () => {
        mutate()
        setClearSelectedRows(true)
    }

    return {
        // SWR INTEGRATION
        activeMerchantId: +merchantId,
        contextMenuItems: contextMenuItems,
        isUploadOpen: openRepresentmentUploadModal,
        closeUpload() {
            setOpenRepresentmentModal(false)
        },
        canViewPartnerCompanyId,
        canViewIssuerDocs,
        canViewOutcomeVerdicts,
        canViewPlatform,
        rehydrateView,
        cases: chargebacksData?.data || [],
        advanceSearchFields: FORM_FIELDS(),
        handleAdvancedSearchSubmit,
        handleAdvancedSearchClear,
        selectedCaseIds,
        error: chargebacksData.error,
        // END SWR INTEGRATION
        handleCaseFilterChange,
        clearSelectedRows,
        setSortInfo,
        disableSelectAll: false,
        swrActions: extendedSwrActions,
        caseActions,
        metricPresets,
        setMetricPresets,
        collapseOpen,
        setCollapseOpen,
        params,
        isLoadingAdvFilterSelect,
        setIsLoadingAdvFilterSelect,
        openEditChargebacksModal,
        setOpenEditChargebacksModal,
        isLoadingAdvFilterFields,
        alertSnackbarMainOpen,
        setAlertSnackbarMainOpen,
        alertSnackbarMainProps,
        setAlertSnackbarMainProps,
    }
}

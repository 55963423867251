import React, { useEffect, useState , useMemo} from 'react'
import axios from 'axios'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useHistory } from 'react-router'
import {
    Button,
    FormGroup,
    FormLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Autocomplete,
    Box,
} from '@mui/material'
import { PresetDateRange, useActiveMerchant } from 'components'
import { clientTheme } from 'theme-exports'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { DefaultValueOption } from 'hooks/cb-api/types'
import idDirectory from './idAttributes'

/** Data for a field of the form */
export interface Field {
    /** Readable representation */
    name: string
    /** initial form state.. */
    initialValue: string
    /** initial compare value when type === "amount" */
    initialCompareValue?: string
    /** identifier unique in the set. */
    key: string
    /** html input type */
    type: HTMLInputElement['type']
    /** options for the field dropdown select */
    defaultValueOptions?: DefaultValueOption[]
    /** api path for selects populated from endpoints */
    apiValuesPath?: string
    /** Whether to render field here or not */
    hideField?: boolean
    /** Used to map some fields which have mismatched names in case */
    filterOverrideName?: string
    /** Use to add 'clear date' icon */
    clearDateIcon?: boolean
    /** Use to disable multiple selections from dropdown */
    disableMultipleSelections?: boolean
}

type TFlexAFormInputs = {
    [key: string]: any
}

export interface FlexibleFormProps {
    /** data objects for each field in the form. */
    fields: Field[]
    /** action to be called on form submit */
    onSubmit: (values: Record<string, string>) => void
    /** Formik-style validation function */
    validate: (values: Record<string, string>) => Record<string, string>
    /** Formik-style initial values, usefull for add/edit functionality */
    initialValues: Record<string, string>
    onClear?: () => void
    metricPresets?: any[] | null
    selectedTabFilter: string | number
    isActiveAdvSearchFilters?: boolean
    setIsLoadingAdvFilterSelect?: (loading: boolean) => void
    inactivateSearchBtn?: boolean
}

const MakeDateRangeFilter = ({
    fieldKey,
    handleValueChange,
    values,
    filterOverrideName = '',
    metricPresets,
    allowFutureDateSelection,
    clearDateIcon = true,
}: {
    fieldKey: string
    handleValueChange: any
    values: any
    filterOverrideName: string | undefined
    metricPresets: any | null
    allowFutureDateSelection: boolean
    clearDateIcon?: boolean
}): React.ReactElement => {
    const foundMetric = metricPresets.find(
        (preset: any) => preset.filter_name === fieldKey
    )
    const [initializedRanges, setInitializedRanges] = useState<string[]>([])

    useEffect(() => {
        if (
            !values[fieldKey] &&
            foundMetric &&
            !initializedRanges.includes(fieldKey)
        ) {
            handleValueChange(
                fieldKey,
                `${foundMetric.filter_value.from}|${foundMetric.filter_value.to}`
            )
            setInitializedRanges((initializedRanges) => [
                ...initializedRanges,
                fieldKey,
            ])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values])

    const filterOverrideValueFound =
        (values[filterOverrideName] && !values[filterOverrideName]) ?? false
    const shouldClear = !values[fieldKey]?.length && !filterOverrideValueFound

    const splitVal = values[fieldKey]?.split('|') || []
    const valueToPass = {
        to: splitVal[1] ?? '',
        from: splitVal[0] ?? '',
    }

    return (
        <div className={'emp-flexAForm-dateInput'} key={values[fieldKey]}>
            <PresetDateRange
                getDateRange={(range: any) => {
                    handleValueChange(fieldKey, range)
                }}
                dateRange={valueToPass}
                fieldKey={fieldKey}
                clearDates={shouldClear}
                className={'emp-flexAForm-dateRangePicker'}
                allowFutureDateSelection={allowFutureDateSelection}
                clearDateRange={() => handleValueChange(fieldKey, '')}
                clearDateIcon={clearDateIcon}
                testId={fieldKey}
            />
        </div>
    )
}

const MakeAmountFilter = ({
    fieldKey,
    initialValue,
    handleValueChange,
    values,
}: {
    fieldKey: string
    initialValue: string
    handleValueChange: any
    values: any
}): React.ReactElement => {
    const compareOptions = [
        {
            value: 'lt',
            label: '<',
        },
        {
            value: 'lte',
            label: '<=',
        },
        {
            value: 'eq',
            label: '=',
        },
        {
            value: 'gt',
            label: '>',
        },
        {
            value: 'gte',
            label: '>=',
        },
    ]

    return (
        <>
            <Select
                className={'emp-flexAForm-fieldSelect'}
                onChange={(e): void => {
                    handleValueChange(
                        `${fieldKey}_op`,
                        e.target.value as string
                    )
                }}
                value={values[`${fieldKey}_op`] || ''}
                variant="standard"
                style={{
                    borderRadius: clientTheme.selectionBox.borderRadius,
                }}
            >
                {compareOptions.map((option: any) => (
                    <MenuItem
                        value={option.value}
                        key={option.value}
                        className={'emp-flexAForm-fieldSelectItem'}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            <TextField
                className={'emp-flexAForm-fieldInput'}
                variant="standard"
                type="number"
                name={fieldKey}
                onChange={(e) => {
                    handleValueChange(fieldKey, e.target.value as string)
                }}
                value={initialValue}
                style={{ borderRadius: clientTheme.selectionBox.borderRadius }}
            />
        </>
    )
}

const MakeSelect = ({
    fieldKey,
    apiValuesPath,
    defaultValueOptions = [{ id: 0, value: 0, name: '' }],
    handleValueChange,
    metricPresets,
    values,
    setIsLoadingAdvFilterSelect,
    disableMultipleSelections,
}: {
    fieldKey: string
    apiValuesPath: string | undefined
    defaultValueOptions: DefaultValueOption[] | undefined
    handleValueChange: any
    metricPresets: any | null
    values: any
    setIsLoadingAdvFilterSelect: (loading: boolean) => void
    disableMultipleSelections: boolean | undefined
}): React.ReactElement => {
    const [apiValues, setApiValues] = useState(defaultValueOptions)
    const [activeLocalValue, setActiveLocalValue] = useState<any[]>([])

    const { id: merchantId } = useActiveMerchant()

    useEffect(() => {
        if (apiValuesPath) {
            if (!merchantId && fieldKey === 'assigned_user_id') return

            axios
                .get(apiValuesPath, {
                    params: {
                        limit: 999,
                        ...(fieldKey === 'assigned_user_id' && {
                            merchant_id: merchantId,
                        }),
                    },
                })
                .then(({ data: { data } }) => {
                    if (!data) return
                    switch (fieldKey) {
                        case 'assigned_user_id':
                            setApiValues(
                                data.map((row: any) => {
                                    return {
                                        id: row.id,
                                        value: row.id,
                                        name: row.fname
                                            ? `${row.fname} ${row.lname}`
                                            : row.username,
                                        optionLabel: row.username,
                                    }
                                })
                            )
                            break
                        case 'reason_code':
                            setApiValues(
                                data.map((row: any) => {
                                    return {
                                        id: row.id,
                                        value: row.id,
                                        name: `${
                                            row.card_type?.name ?? 'N/A'
                                        } - ${row.code}`,
                                    }
                                })
                            )
                            break
                        case 'currency':
                        case 'currency_id':
                            setApiValues(
                                data.map((row: any) => {
                                    return {
                                        id: row.id,
                                        value: row.id,
                                        name: row.currency,
                                    }
                                })
                            )
                            break
                        default:
                            setApiValues(
                                data.map((row: any) => {
                                    return {
                                        value: row.id,
                                        ...row,
                                    }
                                })
                            )
                            break
                    }
                })
        }
    }, [apiValuesPath, fieldKey, merchantId])

    useEffect(() => {
        if (metricPresets.length) {
            const foundMetric = metricPresets.find(
                (preset: any) => preset.filter_name === fieldKey
            )

            if (foundMetric && fieldKey === 'reason_code') {
                setIsLoadingAdvFilterSelect(true)
                axios
                    .get('/cm/gen/rc?limit=999&is_fraud=1')
                    .then(({ data: { data } }) => {
                        const values = data.map((row: any) => {
                            return {
                                value: row.id,
                                id: row.id,
                                name: row.code,
                            }
                        })

                        setActiveLocalValue(values)
                    })
                    .finally(() => setIsLoadingAdvFilterSelect(false))
            } else if (foundMetric && fieldKey !== 'reason_code') {
                setActiveLocalValue(foundMetric.filter_value)
            }
            handleValueChange(
                fieldKey,
                activeLocalValue.flatMap((val: any) => val.value)
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldKey, metricPresets])

    useEffect(() => {
        if (activeLocalValue.length) {
            handleValueChange(
                fieldKey,
                activeLocalValue.flatMap((val: any) => val.value)
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeLocalValue])

    useEffect(() => {
        if (values[fieldKey] === '' && activeLocalValue.length) {
            setActiveLocalValue([])
        }
    }, [fieldKey, values, activeLocalValue, setActiveLocalValue])

    return (
        <div key={values[fieldKey]?.length?.toString()}>
            <Autocomplete
                className={`emp-flexAForm-fieldAutoComplete`}
                sx={{
                    '& .MuiChip-root': {
                        ...clientTheme.labelTags,
                    },
                    borderRadius: clientTheme.selectionBox.borderRadius,
                }}
                autoComplete
                multiple
                limitTags={1}
                size="small"
                getOptionLabel={(option) => {
                    if (!apiValues.length) return ''
                    if (typeof option === 'object') {
                        return (
                            apiValues.find(
                                (opt: DefaultValueOption) =>
                                    opt.id === option.id
                            )?.name || ''
                        )
                    }
                    return (
                        apiValues.find(
                            (opt: DefaultValueOption) => opt.id === option
                        )?.name || ''
                    )
                }}
                isOptionEqualToValue={(option: any, value: any) => {
                    return +option.id === +value.id
                }}
                options={Array.isArray(apiValues) ? apiValues : []}
                onChange={(_e, val) => {
                    if (disableMultipleSelections && val.length) {
                        const lastSelectedVal = val?.pop()
                        setActiveLocalValue([lastSelectedVal])
                        handleValueChange(
                            fieldKey,
                            lastSelectedVal?.value ?? ''
                        )
                    } else {
                        setActiveLocalValue(val)
                        const flattenedVals = val.flatMap((val) => val.value)
                        handleValueChange(
                            fieldKey,
                            flattenedVals.length ? flattenedVals : ''
                        )
                    }
                }}
                value={activeLocalValue}
                renderInput={(params: any): React.ReactNode => (
                    <TextField
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                        }}
                        style={{
                            borderRadius: clientTheme.selectionBox.borderRadius,
                        }}
                        variant="standard"
                    />
                )}
                renderOption={(props, option) => (
                    <Box component="li" {...props} key={`key-${option.id}`}>
                        {option?.optionLabel ?? option?.name ?? ''}
                    </Box>
                )}
            />
        </div>
    )
}

const MakeUserDefinedMultiSelect = ({
    fieldKey,
    handleValueChange,
    value,
}: {
    fieldKey: string
    handleValueChange: any
    value: string
}): React.ReactElement => {
    const [activeLocalValue, setActiveLocalValue] = useState<any[]>([])

    useEffect(() => {
        if (!value) setActiveLocalValue([])
    }, [value])

    return (
        <div key={fieldKey.toString()}>
            <Autocomplete
                className={`emp-flexAForm-fieldAutoComplete`}
                sx={{
                    '& .MuiChip-root': {
                        ...clientTheme.labelTags,
                    },
                    borderRadius: clientTheme.selectionBox.borderRadius,
                }}
                multiple
                options={activeLocalValue}
                value={activeLocalValue}
                freeSolo
                limitTags={1}
                size="small"
                getOptionLabel={(option) => option}
                onChange={(_e, val) => {
                    setActiveLocalValue(val)
                    handleValueChange(fieldKey, val)
                }}
                clearOnBlur
                renderInput={(params) => (
                    // @ts-ignore
                    <TextField
                        {...params}
                        variant="standard"
                        InputProps={{
                            ...params.InputProps,
                            ...(!value && { startAdornment: [] }),
                        }}
                        onBlur={(e: any) => {
                            const targetValue = e.target.value
                            if (
                                targetValue.length &&
                                !activeLocalValue.includes(targetValue)
                            ) {
                                setActiveLocalValue([
                                    ...activeLocalValue,
                                    e.target.value,
                                ])
                                handleValueChange(fieldKey, [
                                    ...activeLocalValue,
                                    e.target.value,
                                ])
                            }
                        }}
                        onKeyDown={(e: any) => {
                            if (e.key.toLowerCase() === 'enter') {
                                e.preventDefault()
                                const targetValue = e.target.value
                                if (
                                    targetValue.length &&
                                    !activeLocalValue.includes(targetValue)
                                ) {
                                    setActiveLocalValue([
                                        ...activeLocalValue,
                                        e.target.value,
                                    ])
                                    handleValueChange(fieldKey, [
                                        ...activeLocalValue,
                                        e.target.value,
                                    ])
                                }
                            }
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius:
                                    clientTheme.selectionBox.borderRadius,
                            },
                        }}
                    />
                )}
                isOptionEqualToValue={(option, value) => {
                    return +option.id === +value.id
                }}
            />
        </div>
    )
}

const AutoSubmitMetrics: React.FC<any> = ({
    metricPresets,
    isActiveAdvSearchFilters,
    submitForm,
    values,
    resetForm,
}: {
    metricPresets: any
    isActiveAdvSearchFilters: boolean
    submitForm: () => void
    values: TFlexAFormInputs
    resetForm: () => void
}) => {
    const [metricsLoaded, setMetricsLoaded] = useState<boolean>(false)

    useEffect(() => {
        !isActiveAdvSearchFilters && resetForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActiveAdvSearchFilters])

    useEffect(() => {
        if (!metricPresets || (metricPresets && metricsLoaded)) return
        const unPopulatedMetrics = metricPresets.filter((metric: any) => {
            return values[metric.filter_name]?.length === 0
        })
        if (!unPopulatedMetrics.length) {
            setMetricsLoaded(true)
            submitForm()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values])

    return null
}

/**
 * A data-driven responsive form
 *
 * @param props {@link FlexibleFormProps}
 */
// eslint-disable-next-line import/prefer-default-export
export const FlexibleForm: React.FC<FlexibleFormProps> = ({
    fields = [],
    onSubmit: handleOnSubmit,
    validate = (): Record<string, string> => ({}),
    onClear = () => {},
    initialValues = {},
    metricPresets,
    selectedTabFilter,
    isActiveAdvSearchFilters,
    setIsLoadingAdvFilterSelect = () => {},
    inactivateSearchBtn = false,
}) => {
    const history = useHistory()
    const { client } = useFeatureToggle('CLIENT')

    const defaultValues = useMemo<TFlexAFormInputs>(() => {
        return Object.fromEntries(
            fields.map((i) => [i.key, initialValues[i.key] || i.initialValue])
        )
    }, [fields, initialValues])

    const {
        reset,
        watch,
        getValues,
        register,
        setValue,
        handleSubmit,
        formState: { isSubmitting },
    } = useForm<TFlexAFormInputs>({
        defaultValues: defaultValues,
        values: defaultValues,
        mode: 'onChange',
    })

    const onSubmit: SubmitHandler<TFlexAFormInputs> = (values) => {
        return handleOnSubmit(values)
    }

    const handleOnChangeValue = (fieldName: any, value: any) => {
        setValue(fieldName, value, { shouldValidate: true, shouldDirty: true })
    }

    return (
        <Paper
            className={'emp-flexAForm-root'}
            elevation={0}
            id={idDirectory.flexAForm.divRoot}
        >
            <form
                className={'emp-flexAForm-container'}
                id={idDirectory.flexAForm.formContainer}
                onSubmit={handleSubmit(onSubmit)}
            >
                {fields
                    .filter((field) => !field.hideField)
                    .map((i) =>
                        [
                            'new_cases',
                            'assigned',
                            'in_progress',
                            'not_disputed',
                            'expired',
                        ].includes(selectedTabFilter.toString()) &&
                        i.name === 'Completed Date' ? null : (
                            <FormGroup
                                key={i.key}
                                id={`${idDirectory.flexAForm.divFilterContainer}-${i.key}`}
                            >
                                <FormLabel
                                    htmlFor={i.key}
                                    className={'emp-flexAForm-fieldLabel'}
                                    id={`${idDirectory.flexAForm.labelFilterTitle}-${i.key}`}
                                >
                                    {i.name}
                                </FormLabel>
                                {/* Form field */}
                                <span
                                    className={`emp-flexAForm-field ${
                                        i.type === 'amount' &&
                                        'emp-flexAForm-hasAmountSelect'
                                    }`}
                                    id={`${idDirectory.flexAForm.divFilterField}-${i.key}`}
                                    aria-label={`${i.name} field`}
                                >
                                    {i.type === 'select' && (
                                        <MakeSelect
                                            fieldKey={i.key}
                                            apiValuesPath={i.apiValuesPath}
                                            handleValueChange={
                                                handleOnChangeValue
                                            }
                                            values={watch()}
                                            metricPresets={metricPresets ?? []}
                                            defaultValueOptions={
                                                i.defaultValueOptions
                                            }
                                            disableMultipleSelections={
                                                i.disableMultipleSelections
                                            }
                                            setIsLoadingAdvFilterSelect={
                                                setIsLoadingAdvFilterSelect
                                            }
                                        />
                                    )}
                                    {i.type === 'userDefinedMultiSelect' && (
                                        <MakeUserDefinedMultiSelect
                                            fieldKey={i.key}
                                            handleValueChange={
                                                handleOnChangeValue
                                            }
                                            value={watch(i.key)}
                                        />
                                    )}
                                    {i.type === 'date' && (
                                        <MakeDateRangeFilter
                                            fieldKey={i.key}
                                            handleValueChange={
                                                handleOnChangeValue
                                            }
                                            values={watch()}
                                            filterOverrideName={
                                                i.filterOverrideName
                                            }
                                            metricPresets={metricPresets ?? []}
                                            allowFutureDateSelection={false}
                                            clearDateIcon={i.clearDateIcon}
                                        />
                                    )}
                                    {/* due date type input fields require future date selection */}
                                    {i.type === 'due-date' && (
                                        <MakeDateRangeFilter
                                            fieldKey={i.key}
                                            handleValueChange={
                                                handleOnChangeValue
                                            }
                                            values={watch()}
                                            filterOverrideName={
                                                i.filterOverrideName
                                            }
                                            metricPresets={metricPresets ?? []}
                                            allowFutureDateSelection={true}
                                        />
                                    )}
                                    {i.type === 'amount' && (
                                        <MakeAmountFilter
                                            fieldKey={i.key}
                                            initialValue={watch(i.key)}
                                            handleValueChange={
                                                handleOnChangeValue
                                            }
                                            values={watch()}
                                        />
                                    )}
                                    {i.type === 'text' && (
                                        <TextField
                                            className={
                                                'emp-flexAForm-fieldInput'
                                            }
                                            variant="standard"
                                            type={i.type}
                                            {...register(i.key)}
                                            name={i.key}
                                            aria-label={`${i.name} ${i.type} input`}
                                            style={{
                                                borderRadius:
                                                    clientTheme.selectionBox
                                                        .borderRadius,
                                            }}
                                        />
                                    )}
                                    {i.type === 'placeholder' && (
                                        <h3
                                            className={
                                                'emp-flexAForm-unknownPlaceholder'
                                            }
                                        >
                                            Unknown property in payload
                                        </h3>
                                    )}
                                </span>
                            </FormGroup>
                        )
                    )}
                <div className={'emp-flexAForm-formBtns'}>
                    <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        disabled={isSubmitting || inactivateSearchBtn}
                        aria-label="Submit search"
                        onClick={() =>
                            history.push({
                                state: {},
                            })
                        }
                        sx={[
                            client === 'jpmc' &&
                                clientTheme.buttons.greyButton.style,
                        ]}
                    >
                        Search
                    </Button>
                    <Button
                        variant={client === 'jpmc' ? 'text' : 'contained'}
                        onClick={() => {
                            reset()
                            onClear()
                        }}
                        aria-label="Clear form"
                        sx={[
                            client === 'jpmc' &&
                                clientTheme.buttons.textOrOutlinedButton.style,
                        ]}
                        className={`${
                            client !== 'jpmc' &&
                            'emp-flexAForm-clearSearchButton'
                        }`}
                    >
                        Clear Search
                    </Button>
                </div>
                <AutoSubmitMetrics
                    metricPresets={metricPresets}
                    isActiveAdvSearchFilters={isActiveAdvSearchFilters}
                    submitForm={handleSubmit(onSubmit)}
                    values={getValues()}
                    resetForm={reset}
                />
            </form>
        </Paper>
    )
}

export default FlexibleForm

import React, { useEffect, useRef, useState } from 'react'
import { Tooltip } from '@mui/material'
import { UploadRepresentmentsRouter, LoadingIndicator } from 'components'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { UploadIcon as UploadIconIcon } from 'components/Icons'
import useIsMounted from 'hooks/utils/useIsMounted'
import { useSWRContext } from 'context/SWRContext'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

interface UploadIconProps {
    className?: string
    size: 'small' | 'medium' | 'large'
    rowIndex: number
    caseIds: number
    deactivated?: boolean
    rehydrateView?: () => void
    row?: any
    caseModel?: any
    testId: string
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
    selectSingleRowClearRest: (index: string) => void
}

// Handles uploading a document when the upload icon is cliked in the data grid
const UploadIcon = React.memo(({
    className,
    size = 'small',
    rowIndex = 0,
    row,
    caseIds = 1,
    deactivated = false,
    rehydrateView = () => {},
    testId,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
    selectSingleRowClearRest,
}: UploadIconProps): React.ReactElement => {
    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only
    const { client } = useFeatureToggle('CLIENT')

    const [uploadSuccess, setUploadSuccess] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const { swrActions } = useSWRContext()

    const iconContainer = {
        small: 'emp-upload-icon-small',
        medium: 'emp-upload-icon-medium',
        large: 'emp-upload-icon-large',
    }

    const {
        isDocStatusCompiling,
    } = swrActions.caseViewActions

    const openModalAndSelectRow = () => {
        setOpenModal(true)
    }

    const closeModal = () => {
        setOpenModal(false)
    }

    const toggleUploadSuccess = () => {
        setUploadSuccess((prev) => !prev)
    }

    let disableUploadIcon = useRef(deactivated)

    const { isMounted } = useIsMounted()

    // ignores the uploadSuccess state on the first mount, and during successive re-renders, disables the icon once uploadSuccess is returned true from UploadRepresentments
    useEffect(() => {
        if (isMounted.current) {
            if (uploadSuccess) {
                disableUploadIcon.current = true
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted])

    const titleText = row.properties.upload_representment_tooltip ?? 'Upload Representment'
    const warningMsg =
        'WARNING: By continuing this dispute, you accept liability of card network arbitration fees of up to $1000.00+, in the event the case is ruled as a loss.'

    const showWarningMsg = client === 'central' && row.cycle_id === 2

    return (
        <div
            className={` ${className} ${'emp-upload-icon-root'} ${
                disableUploadIcon.current ? 'disabled-icon' : ''
            } ${iconContainer[size]}`}
        >
            <UploadRepresentmentsRouter
                cases={[row]}
                caseIds={[caseIds]}
                openModal={openModal}
                toggleModal={closeModal}
                toggleUploadSuccess={toggleUploadSuccess}
                rehydrateView={rehydrateView}
                setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                fromUploadIcon={true}
                rowIndex={rowIndex}
                selectSingleRowClearRest={selectSingleRowClearRest}
            />
            <Tooltip
                arrow
                placement="top"
                title={
                    isDocStatusCompiling(row.status_id)
                        ? 'Doc(s) compiling..'
                        : showWarningMsg
                        ? warningMsg
                        : titleText
                }
            >
                <div>
                    {isDocStatusCompiling(row.status_id) ? (
                        <LoadingIndicator
                            iconOnly
                            className={'emp-uploadIcon-loadingIcon'}
                        />
                    ) : (
                        <UploadIconIcon
                            onClick={
                                deactivated || isReadOnly
                                    ? () => {}
                                    : openModalAndSelectRow
                            }
                            disabled={deactivated}
                            testId={`${testId}-upload-icon-${rowIndex}`}
                        />
                    )}
                </div>
            </Tooltip>
        </div>
    )
})

export default UploadIcon

import React from 'react'
import FlexAForm, { Field } from './FlexAForm'

export const formatAdvancedFilterFields = (fields: Field[]) => {
    const updatedFields: Field[] = []

    fields.forEach((field: Field) => {
        updatedFields.push(field)

        // Need to add field for compare operator when filter type is 'amount'.
        if (field.type === 'amount') {
            updatedFields.push({
                key: `${field.key}_op`,
                filterOverrideName: field.filterOverrideName
                    ? `${field.filterOverrideName}_op`
                    : undefined,
                name: '',
                initialValue: field.initialCompareValue ?? 'eq',
                type: 'select',
                apiValuesPath: undefined,
                defaultValueOptions: undefined,
                disableMultipleSelections: false,
                clearDateIcon: false,
                hideField: true,
            })
        }
    })

    return updatedFields
}

interface IAdvancedFiltersProps {
    className?: string
    id?: string
    onSubmit: (value: Record<string, string>) => void
    fields: Field[]
    metricPresets?: any[] | null
    validate: () => Record<string, string>
    initialValues: Record<string, string>
    onClear: () => void
    selectedTabFilter?: string | number
    isActiveAdvSearchFilters?: boolean
    setIsLoadingAdvFilterSelect?: (loading: boolean) => void
    inactivateSearchBtn?: boolean
}

/**
 * Render the various advanced filters for Chargebacks
 * @note Mocked, not operational
 * @todo Complete this functionality
 */
const AdvancedFilters = ({
    className,
    id,
    onSubmit,
    selectedTabFilter = '',
    ...rest
}: IAdvancedFiltersProps) => {

    return (
        <>
            <FlexAForm
                {...rest}
                onSubmit={onSubmit}
                selectedTabFilter={selectedTabFilter}
            />
        </>
    )
}

export default AdvancedFilters

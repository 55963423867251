import { useEffect, useState } from 'react'
import useIsMounted from 'hooks/utils/useIsMounted'
import axios from 'axios'
import { useUiStateContext } from 'context/UiState/UiStateContext'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

interface UseFilterAlerts {
    descriptorOptions: { id: number; name: string }[]
    searchValue: string
    handleSearch: (val: string) => void
}

export const useFilterAlerts = (
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void,
    setAlertSnackbarMainOpen: (status: boolean) => void
): UseFilterAlerts => {
    const [descriptorOptions, setDescriptorOptions] = useState<
        { id: number; name: string }[]
    >([{ id: 0, name: 'All Descriptors' }])
    const [searchValue, setSearchValue] = useState('')

    const uiState = useUiStateContext()

    const { isMounted } = useIsMounted()

    // TODO: Refactor to catch All Descriptors and send as empty search val
    const handleSearch = (val: string) => setSearchValue(val)

    useEffect(() => {
        const getDescriptorOptions = async () => {
            const descriptors = axios.get('/cm/svc/descriptors', {
                params: {
                    merchant_id:
                        uiState.activeMerchant?.id ??
                        uiState.whoami?.merchant?.id,
                    search: searchValue,
                },
            })

            return await descriptors
        }
        // only GETS descriptiors if the uiState has an active merchant id
        if (uiState.activeMerchant?.id || uiState.whoami?.merchant?.id) {
            getDescriptorOptions()
                .then(({ data: descriptors }) => {
                    if (isMounted)
                        setDescriptorOptions([
                            { id: 0, name: 'All Descriptors' },
                            ...descriptors.data.map(
                                (descriptor: string, index: number) => ({
                                    id: index++,
                                    name: descriptor,
                                })
                            ),
                        ])
                })
                .catch(() => {
                    setAlertSnackbarMainProps({
                        title: 'Error',
                        message:
                            'The was a problem fetching the descriptor options.',
                        intent: 'error',
                    })
                    setAlertSnackbarMainOpen(true)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted, uiState.activeMerchant, uiState.whoami, searchValue])

    return {
        descriptorOptions,
        searchValue,
        handleSearch,
    }
}

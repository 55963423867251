import { FeaturePayload } from '../shared'

/**
 * @description Feature configuruation payload for EMP frontend
 *
 * {@link FeaturePayload}
 */

const featuresConfig: FeaturePayload = {
    CLIENT: { client: 'worldpay' },
    _TEST: {
        enabled: true,
    },
    SIGN_IN_LOGO: {
        enabled: true,
        inverted: false,
    },
    SITE_NAME: {
        site_name: 'Chargebacks911',
        enabled: true,
    },
    MAIN_TOPBAR: {
        LOGO_IMAGE: {
            enabled: true,
        },
        LOGO_TEXT: {
            enabled: false,
            logo_text: 'Worldpay',
            logo_color: undefined,
        },
        PAGE_TITLE: {
            enabled: false,
        },
        TEXT_TITLE: {
            enabled: false,
            text_title: '',
        },
    },
    MAIN_SIDEBAR: {
        enabled: true,
        client_theme_active: false,
        MERCHANT_NAME: {
            enabled: true,
        },
        NAV_SELECTION_UI: {
            type: 'box',
        },
    },
    MAIN_FOOTER: {
        client_name: 'Chargebacks 911',
        enabled: false,
        positionRight: false,
        footerTitle: '',
        privacy_policy_link: undefined,
    },
    MERCHANT_SWITCHER: {
        enabled: true,
        MERCHANT_LIST: {
            enabled_merchant_alias: false,
        },
    },
    DASHBOARD: {
        METRICS: {
            enabled: true,
            metric_variant: 'worldpay',
        },
        CHARTS: {
            enabled: true,
            pie_chart: {
                variant: 'chargebacksByCardType',
                title: 'Chargebacks By Card Type (This Month)',
            },
        },
    },
    MERCHANT_HIERARCHY: {
        SELECT_SEARCH_TYPE: {
            enabled: false,
        },
        MERCHANT_ALIAS: {
            enabled: false,
            input_edit_label: '',
        },
        MERCHANT_EDIT: {
            enabled: true,
            input_edit_label: 'Partner Company ID',
        },
        MERCHANT_UPDATE: {
            enabled: true,
            input_edit_label: 'Partner Company ID',
        },
        MID_DETAILS: {
            enabled: true,
            input_edit_label: 'MID Alias',
        },
        MID_DETAILS_ID: {
            enabled: true,
            input_edit_label: 'Merchant ID',
        },
        MID_UPDATE: {
            enabled: true,
            input_edit_label: 'MID Alias',
        },
        INHERIT_MERCHANT_SETTING: {
            enabled: true,
        },
        MERCHANT_SERVICE_LEVELS: {
            basic_service_enabled: true,
            pro_service_enabled: true,
            full_service_enabled: false,
        },
        DEFLECTORS: {
            enabled: true,
        },
        SHOW_HELIX_MID: {
            enabled: false,
            input_edit_label: '',
        },
        SHOW_MIDS_WPG_FIELD: {
            enabled: true,
        },
        SHOW_MID_HISTORY: {
            enabled: true,
        },
        SHOW_ISSUER_DOC_TOGGLE: {
            enabled: true,
        },
    },
    CHARGEBACKS: {
        enabled: true,
        form_field_variant: 'worldpay',
        AUTO_ACCEPT: {
            enabled: false,
        },
        HELP_LINK: {
            enabled: false,
            text: '',
            url: '',
        },
        CASE_FILTERS: {
            enabled: true,
            PRIMARY_EXPIRING_X_DAYS: {
                enabled: true,
            },
        },
        DATA_GRID: {
            EDIT_CHARGEBACKS_BUTTON: {
                edit_verdict_only: false,
                disable_status_groups: [],
            },
            CONTEXT_MENU: {
                enabled: true,
            },
        },
        CHARGEBACK_EXPORT_HEADERS: {
            enabled: true,
            headers: [
                { accessor: 'merchant_name', label: undefined },
                { accessor: 'processor', label: 'Platform' },
                { accessor: 'date_due', label: undefined },
                { accessor: 'verdict', label: 'Outcome' },
                { accessor: 'id', label: 'Chargebacks ID' },
                { accessor: 'case_no', label: undefined },
                { accessor: 'order_id', label: undefined },
                { accessor: 'ticket_itin_num', label: 'Airline Ticket Number' },
                { accessor: 'cycle', label: undefined },
                { accessor: 'mid', label: undefined },
                { accessor: 'mid_alias', label: undefined },
                { accessor: 'cc_type', label: 'Card Type' },
                { accessor: 'reason_code', label: undefined },
                { accessor: 'reason_category', label: undefined },
                { accessor: 'date_post', label: undefined },
                { accessor: 'is_credit', label: 'Credit' },
                { accessor: 'dispute_amt', label: undefined },
                { accessor: 'dispute_currency', label: undefined },
                { accessor: 'date_trans', label: undefined },
                { accessor: 'trans_amt', label: undefined },
                { accessor: 'trans_currency', label: undefined },
                { accessor: 'cc_bin', label: undefined },
                { accessor: 'cc_last_four', label: undefined },
                { accessor: 'status_group', label: 'Status' },
                { accessor: 'arn', label: undefined },
                { accessor: 'date_completed', label: undefined },
                { accessor: 'chargebacks_comment', label: undefined },
            ],
        },
    },
    CASE_MANAGEMENT: {
        UPGRADE_ALERT: {
            enabled: false,
            roles: ['cb911_admin', 'partner_admin', 'partner_user'],
        },
        AUTO_ACCEPT: {
            enabled: false,
        },
        CASE_MANAGEMENT_EXPORT_HEADERS: {
            enabled: false,
            headers: [],
        },
    },
    HELP: {
        ADD_ONS: {
            client_name: 'Worldpay',
            enabled: true,
            topicTitles: ['Chargebacks', 'User Management', 'Profile Settings'],
            videoURLs: {
                chargebacks:
                    'Navigating_Chargebacks_Using_Advanced_Search_-_Unbranded.mp4',
                alerts: '',
                caseManagement: '',
                userManagement: 'user_management_unbranded.mp4',
                profileSettings: 'Managing_Profile_Settings_-_Unbranded.mp4',
                caseBuilder: '',
                ertsFullService: '',
            },
        },
        USER_DOC: {
            client_name: 'Worldpay',
            enabled: true,
            user_guide_link_is_local_file: true,
            user_guide_link: 'Disputes_Portal_User_Guide.pdf',
        },
    },
    REPORTS: {
        CHARTS: {
            PARTNER_ADMIN: {
                enabled: true,
                default_date_range: {
                    cbByTransMonthPctLine: {
                        enabled: true,
                        value: 365,
                    },
                },
                // Be cognizant of the order you define chart variants, they render in the order of the chart_variants array
                // Each section renders the chart groups into their own divs, from top to bottom.
                // Be cognizant of the order you define chart variants, they render in the order of the chart variant array
                charts_and_position: {
                    section_1: {
                        type: 'grid',
                        charts: [
                            'cbByTransMonthPctArea',
                            'cbByTransAmountPctBar',
                        ],
                    },
                    section_2: {
                        type: 'grid',
                        charts: [
                            'cbTransRatioBytransDateWeekPctArea',
                            'averageSpanCbTransPostDatebyWeekPctArea',
                        ],
                    },
                    section_3: {
                        type: 'card',
                        charts: [
                            'reasonCodePctTop5Donut',
                            'cardTypeDonut',
                            'cbByIssuerPctTop10Donut',
                        ],
                    },
                },
            },
            MERCHANT_ADMIN: {
                enabled: true,
                default_date_range: {
                    cbByTransMonthPctLine: {
                        enabled: true,
                        value: 365,
                    },
                },
                charts_and_position: {
                    section_1: {
                        type: 'grid',
                        charts: [
                            'cbByTransMonthPctArea',
                            'cbByTransAmountPctBar',
                            'averageSpanCbTransPostDatebyWeekPctArea',
                        ],
                    },
                    section_2: {
                        type: 'card',
                        charts: [
                            'reasonCodePctTop5Donut',
                            'cardTypeDonut',
                            'cbByIssuerPctTop10Donut',
                        ],
                    },
                },
            },
        },
        FILTERS: {
            ALL_GATEWAY_IDS: {
                enabled: false,
            },
            ALL_PLATFORMS: {
                enabled: false,
            },
        },
    },
    ERT: {
        ADD_ERT: {
            enabled: false,
            roles: [],
        },
    },
    USERS: {
        READ_ONLY_OPTION: {
            enabled: false,
        },
    },
    ALERTS: {
        AUTO_REFUND_RULES: {
            enabled: true,
        },
    },
    ADVANCED_SEARCH_FILTERS: {
        CASE_MANAGEMENT: {
            enabled: true,
            filters: [
                {
                    key: 'id',
                    name: 'Chargeback ID',
                    initialValue: '',
                    type: 'text',
                    filterOverrideName: 'case_id',
                },
                {
                    key: 'case_no',
                    name: 'Case Reference',
                    initialValue: '',
                    type: 'userDefinedMultiSelect',
                },
                {
                    key: 'order_id',
                    name: 'Transaction Reference',
                    initialValue: '',
                    type: 'text',
                    filterOverrideName: 'trans_id',
                },
                {
                    key: 'mid_alias',
                    name: 'MID Alias',
                    initialValue: '',
                    type: 'text',
                },
                {
                    key: 'mid',
                    name: 'MID (Merchant Account ID)',
                    initialValue: '',
                    type: 'text',
                },
                {
                    key: 'cycle_id',
                    name: 'Cycle',
                    initialValue: '',
                    type: 'select',
                    apiValuesPath: `/cm/gen/cycles`,
                    filterOverrideName: 'cycle',
                },
                {
                    key: 'cc_last_4',
                    name: 'Card number',
                    initialValue: '',
                    type: 'text',
                    filterOverrideName: 'cc_last_four',
                },
                {
                    key: 'reason_code',
                    name: 'Reason Code',
                    initialValue: '',
                    type: 'select',
                    apiValuesPath: `cm/gen/rc`,
                    filterOverrideName: 'reason_code_id',
                },
                {
                    key: 'reason_category',
                    name: 'Reason Category',
                    initialValue: '',
                    type: 'select',
                    apiValuesPath: '/cm/gen/rc-categories',
                },
                {
                    key: 'trans_amt',
                    name: 'Transaction Amount',
                    initialValue: '',
                    type: 'amount',
                    initialCompareValue: 'eq',
                },
                {
                    key: 'dispute_amt',
                    name: 'Dispute Amount',
                    initialValue: '',
                    type: 'amount',
                    filterOverrideName: 'case_amt',
                    initialCompareValue: 'eq',
                },
                {
                    key: 'currency_id',
                    name: 'Dispute Currency',
                    initialValue: '',
                    type: 'select',
                    apiValuesPath: `/cm/gen/currencies`,
                },
                {
                    key: 'card_type',
                    name: 'Card Type',
                    initialValue: '',
                    type: 'select',
                    apiValuesPath: `/cm/gen/cctypes`,
                },
                {
                    key: 'flag',
                    name: 'Flag',
                    initialValue: '',
                    type: 'select',
                    apiValuesPath: `/cm/gen/flags`,
                    filterOverrideName: 'flag_id',
                },
                {
                    key: 'arn',
                    name: 'ARN',
                    initialValue: '',
                    type: 'text',
                },
                {
                    key: 'assigned_user_id',
                    name: 'Assigned User',
                    initialValue: '',
                    type: 'select',
                    apiValuesPath: `/users/users`,
                },
                {
                    key: 'is_guaranteed',
                    name: 'Is Guaranteed',
                    initialValue: '',
                    type: 'select',
                    defaultValueOptions: [
                        { id: 1, value: 1, name: 'Yes' },
                        { id: 2, value: 0, name: 'No' },
                    ],
                    disableMultipleSelections: true,
                },
                {
                    key: 'trans_date',
                    name: 'Transaction Date',
                    initialValue: '',
                    type: 'date',
                    filterOverrideName: 'trans_date_range',
                },
                {
                    key: 'post_date',
                    name: 'Post Date',
                    initialValue: '',
                    type: 'date',
                    filterOverrideName: 'date_range_post',
                },
                {
                    key: 'date_due',
                    name: 'Due Date',
                    initialValue: '',
                    type: 'date',
                    filterOverrideName: 'due_date_range',
                },
                {
                    key: 'date_completed',
                    name: 'Completed Date',
                    initialValue: '',
                    type: 'date',
                    filterOverrideName: 'date_completed_range',
                },
            ],
        },
        CHARGEBACKS: {
            enabled: true,
            filters: [
                {
                    key: 'id',
                    name: 'Chargeback ID',
                    initialValue: '',
                    type: 'text',
                    filterOverrideName: 'case_id',
                },
                {
                    key: 'case_no',
                    name: 'Case Reference',
                    initialValue: '',
                    type: 'userDefinedMultiSelect',
                },
                {
                    key: 'order_id',
                    name: 'Transaction Reference',
                    initialValue: '',
                    type: 'text',
                },
                {
                    key: 'mid_alias',
                    name: 'MID Alias',
                    initialValue: '',
                    type: 'text',
                },
                {
                    key: 'mid',
                    name: 'MID (Merchant Account ID)',
                    initialValue: '',
                    type: 'text',
                },
                {
                    key: 'cycle_id',
                    name: 'Cycle',
                    initialValue: '',
                    type: 'select',
                    apiValuesPath: `/cm/gen/cycles`,
                    filterOverrideName: 'cycle',
                },
                {
                    key: 'cc_bin',
                    name: 'Card BIN',
                    initialValue: '',
                    type: 'text',
                },
                {
                    key: 'cc_last_4',
                    name: 'Card Last Four',
                    initialValue: '',
                    type: 'text',
                    filterOverrideName: 'cc_last_four',
                },
                {
                    key: 'reason_code',
                    name: 'Reason Code',
                    initialValue: '',
                    type: 'select',
                    apiValuesPath: `cm/gen/rc`,
                    filterOverrideName: 'reason_code_id',
                },
                {
                    key: 'reason_category',
                    name: 'Reason Category',
                    initialValue: '',
                    type: 'select',
                    apiValuesPath: '/cm/gen/rc-categories',
                },
                {
                    key: 'trans_amt',
                    name: 'Transaction Amount',
                    initialValue: '',
                    type: 'amount',
                    initialCompareValue: 'eq',
                },
                {
                    key: 'post_date',
                    name: 'Post Date',
                    initialValue: '',
                    type: 'date',
                    filterOverrideName: 'date_range_post',
                },
                {
                    key: 'trans_date',
                    name: 'Transaction Date',
                    initialValue: '',
                    type: 'date',
                    filterOverrideName: 'trans_date_range',
                },
                {
                    key: 'dispute_amt',
                    name: 'Dispute Amount',
                    initialValue: '',
                    type: 'amount',
                    filterOverrideName: 'case_amt',
                    initialCompareValue: 'eq',
                },
                {
                    key: 'currency',
                    name: 'Dispute Currency',
                    initialValue: '',
                    type: 'select',
                    apiValuesPath: `/cm/gen/currencies`,
                    filterOverrideName: 'currency_id',
                },
                {
                    key: 'card_type',
                    name: 'Card Type',
                    initialValue: '',
                    type: 'select',
                    apiValuesPath: `/cm/gen/cctypes`,
                },
                {
                    key: 'status_group_id',
                    name: 'Status',
                    initialValue: '',
                    type: 'select',
                    apiValuesPath: '/cm/gen/status-groups',
                },
                {
                    key: 'verdict',
                    name: 'Outcome',
                    initialValue: '',
                    type: 'select',
                    apiValuesPath: '/cm/gen/verdicts',
                },
                {
                    key: 'arn',
                    name: 'ARN',
                    initialValue: '',
                    type: 'text',
                },
                {
                    key: 'is_guaranteed',
                    name: 'Is Guaranteed',
                    initialValue: '',
                    type: 'select',
                    defaultValueOptions: [
                        { id: 1, value: 1, name: 'Yes' },
                        { id: 2, value: 0, name: 'No' },
                    ],
                    disableMultipleSelections: true,
                },
                {
                    key: 'date_due',
                    name: 'Due Date',
                    initialValue: '',
                    type: 'due-date',
                    filterOverrideName: 'due_date_range',
                },
                {
                    key: 'date_completed',
                    name: 'Completed Date',
                    initialValue: '',
                    type: 'date',
                    filterOverrideName: 'date_completed_range',
                },
            ],
        },
        INVALID_CHARGEBACKS: {
            enabled: true,
            filters: [
                {
                    key: 'id',
                    name: 'Chargeback ID',
                    initialValue: '',
                    type: 'text',
                    filterOverrideName: 'case_id',
                },
                {
                    key: 'case_no',
                    name: 'Case Reference',
                    initialValue: '',
                    type: 'userDefinedMultiSelect',
                },
                {
                    key: 'order_id',
                    name: 'Transaction Reference',
                    initialValue: '',
                    type: 'text',
                },
                {
                    key: 'mid_alias',
                    name: 'MID Alias',
                    initialValue: '',
                    type: 'text',
                },
                {
                    key: 'mid',
                    name: 'MID (Merchant Account ID)',
                    initialValue: '',
                    type: 'text',
                },
                {
                    key: 'cycle_id',
                    name: 'Cycle',
                    initialValue: '',
                    type: 'select',
                    apiValuesPath: `/cm/gen/cycles`,
                    filterOverrideName: 'cycle',
                },
                {
                    key: 'cc_bin',
                    name: 'Card BIN',
                    initialValue: '',
                    type: 'text',
                },
                {
                    key: 'cc_last_4',
                    name: 'Card Last Four',
                    initialValue: '',
                    type: 'text',
                    filterOverrideName: 'cc_last_four',
                },
                {
                    key: 'reason_code',
                    name: 'Reason Code',
                    initialValue: '',
                    type: 'select',
                    apiValuesPath: `cm/gen/rc`,
                    filterOverrideName: 'reason_code_id',
                },
                {
                    key: 'reason_category',
                    name: 'Reason Category',
                    initialValue: '',
                    type: 'select',
                    apiValuesPath: '/cm/gen/rc-categories',
                },
                {
                    key: 'trans_amt',
                    name: 'Transaction Amount',
                    initialValue: '',
                    type: 'amount',
                    initialCompareValue: 'eq',
                },
                {
                    key: 'post_date',
                    name: 'Post Date',
                    initialValue: '',
                    type: 'date',
                    filterOverrideName: 'date_range_post',
                },
                {
                    key: 'trans_date',
                    name: 'Transaction Date',
                    initialValue: '',
                    type: 'date',
                    filterOverrideName: 'trans_date_range',
                },
                {
                    key: 'dispute_amt',
                    name: 'Dispute Amount',
                    initialValue: '',
                    type: 'amount',
                    filterOverrideName: 'case_amt',
                    initialCompareValue: 'eq',
                },
                {
                    key: 'currency',
                    name: 'Dispute Currency',
                    initialValue: '',
                    type: 'select',
                    apiValuesPath: `/cm/gen/currencies`,
                    filterOverrideName: 'currency_id',
                },
                {
                    key: 'card_type',
                    name: 'Card Type',
                    initialValue: '',
                    type: 'select',
                    apiValuesPath: `/cm/gen/cctypes`,
                },
                {
                    key: 'status_group_id',
                    name: 'Status',
                    initialValue: '',
                    type: 'select',
                    apiValuesPath: '/cm/gen/status-groups',
                },
                {
                    key: 'arn',
                    name: 'ARN',
                    initialValue: '',
                    type: 'text',
                },
                {
                    key: 'date_due',
                    name: 'Due Date',
                    initialValue: '',
                    type: 'due-date',
                    filterOverrideName: 'due_date_range',
                },
                {
                    key: 'date_completed',
                    name: 'Completed Date',
                    initialValue: '',
                    type: 'date',
                    filterOverrideName: 'date_completed_range',
                },
            ],
        },
    },
    DATAGRID: {
        ZEBRA_STRIPED_ROWS: {
            enabled: true,
        },
        ENABLE_OVERFLOW_COLUMNS: {
            enabled: true,
        },
        ENABLE_HEADER_WRAP: {
            enabled: false, // If switching, check column definition widths. Columns might overflow off DataGridSlim.
        },
    },
    ACCENT_AREA: {
        client_theme_top_border: false,
        title_icon: true,
    },
    SEARCH_BAR: {
        enabled: true,
        style: 'outlined',
    },
    FORM_FIELDS: {
        enabled: true,
        style: 'outlined',
    },
    HAS_THEME_VARIATIONS: {
        enabled: false,
    },
    SHOW_AG_SWITCHER: {
        enabled: false,
    },
    ALERTS_IFRAME: {
        enabled: true,
    },
    HAS_REMEDY: {
        enabled: true,
    },
}

export default featuresConfig

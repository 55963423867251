import { useActiveMerchant } from 'components'
import axios from 'axios'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import {
    useSWRActions,
    SWRActions,
    useSwrData,
    User,
    useParams,
} from 'hooks/cb-api'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { DownloadParams } from 'lib/Cases'

export type Download = (
    params: DownloadParams,
    title: string,
    isSinglePage: boolean,
    headers?: string[],
    passedMerchantId?: number
) => Promise<unknown>

interface UserVM {
    users: User[]
    swrActions: SWRActions
    params: { [key: string]: any }
    [key: string]: any
}

export const useUsers = (): UserVM => {
    const { user: currentUser } = useAuthedUser()
    const location = useLocation<{ success?: boolean; isEdit?: boolean }>()
    const isFederatedUser = currentUser?.is_federated
    const [userSuccessfullyAdded, setUserSuccessfullyAdded] = useState(
        Boolean(location.state?.success)
    )
    const { id: merchantId } = useActiveMerchant()
    const { params, setQueryParams, setParams, reset } = useParams()

    const endpointCongfig = {
        paramInUrl: false,
        url: 'users/users',
        id: {
            key: 'merchant_id',
            value: merchantId,
        },
    }

    const usersData = useSwrData(endpointCongfig, params)

    const swrActions = useSWRActions(usersData, {
        setParams,
        setQueryParams,
        params,
        reset,
    })

    useEffect(() => {
        let timeout: NodeJS.Timeout | null = null
        if (userSuccessfullyAdded) {
            timeout = setTimeout(() => {
                setUserSuccessfullyAdded(false)
            }, 3 * 1_000)
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [userSuccessfullyAdded])

    const selectAllRowsInStore = () => {
        // users.selectAllRows()
    }

    const unselectAllRowsInStore = () => {
        // users.clearSelectedRows()
    }

    return {
        users: usersData.data ?? [],
        swrActions: swrActions,
        //////
        error: usersData.error,
        currentUser,
        isUserJustAdded: userSuccessfullyAdded,
        wasUserEdited: location?.state?.isEdit ?? false,
        selectAllRowsInStore,
        unselectAllRowsInStore,
        isFederatedUser,
        params,
    }
}

export const useUsersDownload = (): {
    downloadXlsx: Download
    isDownloadXlsxError: boolean
    isLoadingDownloadXlsx: boolean
    isLoadingDownloadXlsxAll: boolean
    isEmailExport: boolean
    setIsEmailExport: (val: boolean) => void
} => {
    const { id: merchantId } = useActiveMerchant()
    const [isDownloadXlsxError, setIsDownloadXlsxError] = useState<boolean>(
        false
    )
    const [isLoadingDownloadXlsx, setIsLoadingDownloadXlsx] = useState<boolean>(
        false
    )
    const [
        isLoadingDownloadXlsxAll,
        setIsLoadingDownloadXlsxAll,
    ] = useState<boolean>(false)
    const [isEmailExport, setIsEmailExport] = useState(false)

    const downloadXlsx: Download = async (
        P: DownloadParams,
        title: string,
        isSinglePage: boolean,
        headers?: string[],
        passedMerchantId?: number
    ) => {
        isSinglePage
            ? setIsLoadingDownloadXlsx(true)
            : setIsLoadingDownloadXlsxAll(true)

        if (P) {
            Object.keys(P).forEach((key) => {
                if (P[key] === '') delete P[key]
            })
        }

        await axios.get(`/users/users.xlsx`, {
            responseType: 'blob',
            params: {
                ...P,
                merchant_id: passedMerchantId ?? merchantId,
                headers: headers ?? [],
            },
            headers: {
                'Content-Type':
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        }).then((r) => {
            if (
                [
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'text/csv',
                ].includes(r.data.type) ||
                isSinglePage
            ) {
                setIsEmailExport(false)

                const url = window.URL.createObjectURL(new Blob([r.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `${title}.xlsx`)
                document.body.appendChild(link)
                link.click()
                link.remove()
            } else {
                setIsEmailExport(true)
            }
        })
        .catch(async (err) => {
            setIsDownloadXlsxError(true)
        })
        .finally(() => {
            isSinglePage
                ? setIsLoadingDownloadXlsx(false)
                : setIsLoadingDownloadXlsxAll(false)
        })
    }
    return {
        downloadXlsx,
        isDownloadXlsxError,
        isLoadingDownloadXlsx,
        isLoadingDownloadXlsxAll,
        isEmailExport,
        setIsEmailExport,
    }
}

import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { CloseAndInvalidate } from '../grids/AlertsGrid'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { LoadingIndicator, StandardModal } from 'components'

interface AlertHistoryProps {
    alertId: number | undefined
    open: boolean
    onClose: CloseAndInvalidate
}

/**
 * @name AlertHistory
 * @description - View alert history
 * @paran alertId - number | undefined: ID of the alert
 * @param open - bool: Determines whether modal is to be open
 * @param onClose - func: toggle open state
 */
const AlertHistory: React.FC<AlertHistoryProps> = ({
    alertId,
    open,
    onClose,
}) => {
    const [history, setHistory] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(true)
    const { user } = useAuthedUser()
    useEffect(() => {
        if (open && user) {
            setLoading(true)
            axios.get(`/cm/svc/alerts/history?merchant_id=${user?.merchant?.id}&alert_id=${alertId}`).then(({data: {data}}) => {
                setHistory(data)
            }).finally(() => {
                setLoading(false)
            })
        }
        return () => {
            setHistory([])
        }
    }, [open, alertId, user])

    return (
        <StandardModal
            openModal={open}
            onModalClose={() => {
                onClose('history')
            }}
            maxModalWidth={'sm'}
            modalHeaderTitle={'View Alert History'}
            testId={'preChargebacksAlertHistory'}
            enablePrimaryActionBtn={false}
            handleSecondaryActionBtn={() => {
                onClose('history')
            }}
        >
            <div id={'preChargebacksAlertsAlertHistory'}>
                {loading ? (
                    <LoadingIndicator />
                ) : (
                    <div className={'emp-historyContainer'}>
                        {history.length ? (
                            history.map((row: any) => {
                                return (
                                    <div className={'emp-historyItem'}>
                                        <div>{row.date}:</div>
                                        <div>
                                            Changed from{' '}
                                            <span className={'emp-blueLabel'}>
                                                {row.old_outcome_name}
                                            </span>{' '}
                                            to{' '}
                                            <span className={'emp-blueLabel'}>
                                                {row.new_outcome_name}
                                            </span>{' '}
                                            by user{' '}
                                            <span className={'emp-blueLabel'}>
                                                {row.username}
                                            </span>
                                            .
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <span>No history found for alert {alertId}.</span>
                        )}
                    </div>
                )}
            </div>
        </StandardModal>
    )
}

export default AlertHistory